import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getFeedbackList } from "../../networking/apiEndpoints";
import { FeedbackModel } from "../../types/components/FeedbackModel";

export const fetchFeedbackList = createAsyncThunk<
AxiosResponse,
any,
  {
  rejectValue:AxiosResponse
}
>(
    "fetchFeedbackModel/fetch",
    async (searchFeedback: { contractTypeId?: string, fromDate?: string, toDate?: string }, thunkApi) => {
       
            var token = localStorage.getItem('token');
            var tenantId = localStorage.getItem('tenantId');
            const dataURL = `${searchFeedback?.contractTypeId === undefined ? ' ' : searchFeedback?.contractTypeId === '' ? ' ' : searchFeedback?.contractTypeId}/${searchFeedback?.fromDate === undefined ? ' ' : searchFeedback?.fromDate === '' ? '%20' : searchFeedback?.fromDate}/${searchFeedback?.toDate === undefined ? '%20' : searchFeedback?.toDate === '' ? '%20' : searchFeedback?.toDate}`;
            const response = await axios.get(getFeedbackList + "/" + dataURL + "/" + tenantId,{
             headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`, tenantId: tenantId },validateStatus: function(status){return status < 500;}
        });
        if (response.status === 401) {
          return thunkApi.rejectWithValue(response);
        }
            return response;       
    }
);

interface FeedBackState {
    data: FeedbackModel[] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus: number;
}

const initialState: FeedBackState = {
    data: [],
    loading: false,
    error: null,
    responseStatus: 200
};

export const feedbackListSlice = createSlice({
    name: "contractTypelist",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeedbackList.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchFeedbackList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(fetchFeedbackList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if (action.payload !== undefined) {
                    state.responseStatus = action.payload.status;
                }
            });
    },
});
export default feedbackListSlice.reducer;