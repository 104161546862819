import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getContractTypeById } from '../../networking/apiEndpoints';
const initialState: any = {
    contractTypeModel: [],
    isLoadingContractType:false,
    responseStatus: 200
};

// Define an async thunk to make the API call
export const getContractTypeId = createAsyncThunk<
AxiosResponse,
string,
  {
  rejectValue:AxiosResponse
}
>(
    'contract/getContractTypeId',
    async (id, thunkApi) => {
        try {
            var tenantId = localStorage.getItem('tenantId');
            const response = await axios.get(getContractTypeById + '/' + id + "/" + tenantId, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
              });
              if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }         
              return response; // Return the data from the API response (should be a boolean)
        } catch (error) {
            throw error;
        }
    }
);

// Define the slice with reducers for pending, fulfilled, and rejected states
const getContractTypeIdSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getContractTypeId.fulfilled, (state, action) => {
            state.contractTypeModel = action.payload.data[0];
            state.isLoadingContractType = false; 
        })
        .addCase(getContractTypeId.pending, (state, action) => {            
            state.isLoadingContractType = true;        
        })
        .addCase(getContractTypeId.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });   
    },
});

export default getContractTypeIdSlice.reducer;
