import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { setActivePortco } from "./setPotcoSlice";

export const usePortcoSetting = () => {
    const {portco} = useSelector((state : RootState) => state.currentPortco);
    const dispatch = useAppDispatch();
    const setPortco = (portco: string) => {
        dispatch(setActivePortco(portco))
    }
    return {setPortco, portco}
} 