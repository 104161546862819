import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getContractTypes, getPromptTemplates } from "../../networking/apiEndpoints";
import { PromptTemplateModel } from "../../types/components/promptTemplate";

export const fetchPromptTemplateList = createAsyncThunk(
    "fetchPromptTemplateList/fetch",
    async (args, thunkApi) => { 
            var token = localStorage.getItem('token');
            var tenantId = localStorage.getItem('tenantId');
            const response = await axios.get(getPromptTemplates + "/" + tenantId,{
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
              });
              if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }                
            return response.data;
    }
);

interface SelectTemplateListState {
    data: PromptTemplateModel[] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus:number;
}

const initialState: SelectTemplateListState = {
    data: [],
    loading: false,
    error: null,
    responseStatus:200
};

export const selectTemplateListSlice = createSlice({
    name: "promtTemplateList",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPromptTemplateList.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchPromptTemplateList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(fetchPromptTemplateList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if(action.payload !== undefined)
                    {
                      state.responseStatus = 401;
                    }
            });
    },
});
export default selectTemplateListSlice.reducer;