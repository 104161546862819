import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { login } from "../../networking/apiEndpoints";
import { UserModel } from "../../types/components/userModel";
import { ResponseModelState } from "../../types/components/responseModel";

const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    responseStatus: 200
};

export const loginApi = createAsyncThunk(
    "login/post",
    async (userModel : UserModel) => {
        try{
            const response = await axios.post(login, userModel, {
                headers:{"Content-Type": "application/json"}
            })
            return response.data;
        } catch(err){
            console.log(err);
        }
    }
);

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(loginApi.fulfilled, (state, action) => {
            state.responseModel = action.payload;                        
        });        
    },
});

export default loginSlice.reducer;
