import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist";

interface IREGModuleMessage {
    mesageOpen : boolean,
    firstTimeOpen : boolean,
}

const initialState : IREGModuleMessage = {
    mesageOpen : false,
    firstTimeOpen : false,
}

const regModuleMessageSlice = createSlice({
    name : "REGModuleMessage",
    initialState,
    reducers : {
        displayREGModuleMessage : (state : IREGModuleMessage, action : PayloadAction<IREGModuleMessage>) => {
            state.mesageOpen = action.payload.mesageOpen;
            state.firstTimeOpen = action.payload.firstTimeOpen;
        }
    },
    extraReducers : builder => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
    }
})

export const {displayREGModuleMessage} = regModuleMessageSlice.actions;
export default regModuleMessageSlice.reducer