import { useNavigate } from "react-router-dom";
import { IMenuItem } from "../../types/components/leftNavTypes";
import { getPermissionsFromToken } from "../../utilities/getPermissionsFromToken";
import { NavREGMeetingDashboard, NavREGSummaryTemplateDashboard, NavREGEmailTemplateDashboard, NavREGUserDashboard, NavREGRolesPermissionDashboard, NavCSTContractsDashboard, NavCSTContractsTypesDashboard, NavCSTContractsTermsDashboard, NavCSTUserFeedbackReport, NavCSTGenerateContractsReport, NavCSTUsersDashboard, NavCSTRolesPermissionDashboard } from "../../utilities/PermissionConstants";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "../../redux/features/getMenuSlice";

export const usePermissionNavList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userPermissions = getPermissionsFromToken();
    const reg_meeting_dashboard  = userPermissions.includes(NavREGMeetingDashboard);
    const reg_summary_template_dashboard  = userPermissions.includes(NavREGSummaryTemplateDashboard);
    const reg_email_template_dashboard  = userPermissions.includes(NavREGEmailTemplateDashboard);
    const reg_user_dashboard  = userPermissions.includes(NavREGUserDashboard);
    const reg_roles_permission_dashboard  = userPermissions.includes(NavREGRolesPermissionDashboard);
      
    const cst_contract_dashboard  = userPermissions.includes(NavCSTContractsDashboard);
    const cst_contract_types_dashboard  = userPermissions.includes(NavCSTContractsTypesDashboard);
    const cst_contract_terms_dashboard  = userPermissions.includes(NavCSTContractsTermsDashboard);
    const cst_user_feedback_report  = userPermissions.includes(NavCSTUserFeedbackReport);
    const cst_generate_contract_report  = userPermissions.includes(NavCSTGenerateContractsReport);
    const cst_user_dashboard  = userPermissions.includes(NavCSTUsersDashboard);
    const cst_roles_permission_dashboard  = userPermissions.includes(NavCSTRolesPermissionDashboard);

    const LeftNavListREG: IMenuItem = {
      ...(reg_meeting_dashboard && {
      Reg: [
        {
          name: "Meeting Summary & Email",
          url: "/rras-follow-up-email-creator",
          icon: "GridViewOutlined",
          visibilitySubMenu: reg_meeting_dashboard,
          subMenu: [],
        },
      ],
    }),
      ...( (reg_summary_template_dashboard || reg_email_template_dashboard) && {
          SystemConfiguration: [
              {
                name: "Lookup Tables",
                url: "/lookup-tables",
                icon: "List",
                visibilitySubMenu: reg_summary_template_dashboard || reg_email_template_dashboard,
                subMenu: [
                  {
                    name: "Summary Templates",
                    url: "/rras-summary-templates",
                    icon: "FiberManualRecordOutlined",
                    visibilitySubMenuItem: reg_summary_template_dashboard,
                  },
                  {
                    name: "Email Templates",
                    url: "/rras-email-templates",
                    icon: "FiberManualRecordOutlined",
                    visibilitySubMenuItem: reg_email_template_dashboard,
                  },
                ],
              }
          ],
      }),
      ...( (reg_user_dashboard || reg_roles_permission_dashboard) && {
      UserManagement: [
        {
          name: "Roles & Permissions",      
          url:`/roles-and-permissions?module=${encodeURIComponent('rass')}`,
          icon: "ManageAccountsOutlined",
          visibilitySubMenu: reg_roles_permission_dashboard,
          subMenu: [],
        },
        {
          name: "User List",      
          url:`/user-list?module=${encodeURIComponent('rass')}`,
          icon: "PeopleAltOutlined",
          visibilitySubMenu: reg_user_dashboard,
          subMenu: [],
        },
      ],
    }),
    };

    const LeftNavListCST: IMenuItem = {
      ...(cst_contract_dashboard && {
      ContractManagement: [
        {
          name: "Contracts",
          url: "/",
          icon: "GridViewOutlined",
          visibilitySubMenu: cst_contract_dashboard,
          subMenu: [],
        },
      ],
      }),
      ...( (cst_contract_types_dashboard || cst_contract_terms_dashboard) && {
        SystemConfiguration: [
          {
            name: "Lookup Tables",
            url: "/lookup-tables",
            icon: "List",
            visibilitySubMenu: cst_contract_types_dashboard || cst_contract_terms_dashboard,
            subMenu: [
              {
                name: "Contract Types",
                url: "/contract-types",
                icon: "FiberManualRecordOutlined",
                visibilitySubMenuItem: cst_contract_types_dashboard,
              },
              {
                name: "Contract Terms",
                url: "/contract-terms",
                icon: "FiberManualRecordOutlined",
                visibilitySubMenuItem: cst_contract_terms_dashboard,
              },
            ],
          },
        ],
      }),
      ...( (cst_user_dashboard || cst_roles_permission_dashboard) && {
        UserManagement: [
          {
            name: "Roles & Permissions",
            url: "/roles-and-permissions",
            icon: "ManageAccountsOutlined",
            visibilitySubMenu: cst_roles_permission_dashboard,
            subMenu: [],
          },
          {
            name: "User List",
            url: "/user-list",
            icon: "PeopleAltOutlined",
            visibilitySubMenu: cst_user_dashboard,
            subMenu: [],
          },
        ],
      }),
      ...( (cst_user_feedback_report || cst_generate_contract_report) && {
        Reports: [
          {
            name: "Users Feedback",
            url: "/users-feedback",
            icon: "AssessmentOutlined",
            visibilitySubMenu: cst_user_feedback_report,
            subMenu: [],
          },
          {
            name: "Contract Reports",
            url: "/contract-reports",
            icon: "AssessmentOutlined",
            visibilitySubMenu: cst_generate_contract_report,
            subMenu: [],
          },
        ],
      }),
    };

    // Utility function for navigation
    const navigateToVisibleItem = (menuList : IMenuItem) => {
      for (const menu of Object.values(menuList)) {
        if (menu && menu.length > 0) {
          // Check main menu visibility
          const visibleMenu = menu.find(item => item.visibilitySubMenu);
          if (visibleMenu && visibleMenu.subMenu.length === 0) {
            dispatch(setActiveMenuItem(visibleMenu.name)) 
            navigate(visibleMenu.url);
            return;
          }
          // Check submenus if no visible main menu found
          for (const item of menu) {
            if (item.subMenu && item.subMenu.length > 0) {
              const visibleSubMenu = item.subMenu.find(subItem => subItem.visibilitySubMenuItem);
              if (visibleSubMenu) {
                dispatch(setActiveMenuItem(visibleSubMenu.name)) 
                navigate(visibleSubMenu.url);
                return;
              }
            }
          }
        }
      }
    };

      return {reg_meeting_dashboard, reg_summary_template_dashboard, reg_email_template_dashboard, reg_user_dashboard, reg_roles_permission_dashboard, cst_contract_dashboard, cst_contract_types_dashboard, cst_contract_terms_dashboard, cst_user_feedback_report, cst_generate_contract_report, cst_user_dashboard, cst_roles_permission_dashboard, LeftNavListREG, LeftNavListCST, navigateToVisibleItem}
}