import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getContractTerms } from '../../networking/apiEndpoints';
import axios, { AxiosResponse } from 'axios';

export const deleteContractTermById = createAsyncThunk(
  "deletecontract/delete",
  async (id: string) => {
    try {
      var token = localStorage.getItem('token');
      const response = await axios.delete(getContractTerms + "/" + id,{
        headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
      });
      return response.status;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchContractTerms = createAsyncThunk<
AxiosResponse,
any,
  {
  rejectValue:AxiosResponse
}
>(
  'contractTerms/fetchContractTerms',
  async (searchTerms: { contractTypeId?: string, method?: string, termName?: string }, thunkApi) => {
    var token = localStorage.getItem('token');
    var tenantId = localStorage.getItem('tenantId');
      const contractTerm = {
        name: searchTerms?.termName,
        contractType: searchTerms?.contractTypeId,
        method: searchTerms?.method,
        tenantId:tenantId
      };      
      const response = await axios.post(getContractTerms, contractTerm, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`, tenantId: tenantId },validateStatus: function(status){return status < 500;}
      });
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }
      return response.data;
    
  }
);
interface ContractTermState {
  data: any;
  loading: boolean;
  error: string | null;
  responseStatus: number;
}

const initialState: ContractTermState = {
  data: [],
  loading: false,
  error: null,
  responseStatus: 200
};

const contractTermSlice = createSlice({
  name: 'contractTerms',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractTerms.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchContractTerms.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        if(action.payload !== undefined)
          {
            state.responseStatus = action.payload.status;
          }
      })
      .addCase(fetchContractTerms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? '';
        if (action.payload !== undefined) {
                state.responseStatus = action.payload.status;
            }
      });
  },
});

export default contractTermSlice.reducer;