import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {AttachContractListResponseState} from "../../types/components/contractDetailsTypes";
import axios, { AxiosResponse } from "axios";
import { getAttachContract } from "../../networking/apiEndpoints";
const initialState: AttachContractListResponseState = {
  attachContractList: {
    id: '',
    tenantId: '',
    name: '',
    description: '',
    isDelete: false,
    contractTypeId: '',
    contractType: '',
    attachContracts: []
},
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchAttachContractList = createAsyncThunk<
  AxiosResponse,
  FormData,
  {
    rejectValue: AxiosResponse;
  }
>(
  "attachContractList/fetch",
  async (formData, thunkApi) => {
    var token = localStorage.getItem('token');
    var tenantId = localStorage.getItem('tenantId');
    formData.append("tenantId",tenantId??'')
    const response = await axios.post(getAttachContract , formData, {
        headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
    });
    if(response.status === 401)
        {
          return thunkApi.rejectWithValue(response);
        } 
    return response;   
  }
);



export const attachContractListSlice = createSlice({
  name: "contractlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAttachContractList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchAttachContractList.fulfilled, (state, action) => {    
      state.attachContractList = action.payload.data;
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
      state.loading = false;
    }) 
    .addCase(fetchAttachContractList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default attachContractListSlice.reducer;
