import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getPermissions } from "../../networking/apiEndpoints";
import { PermissionResponseModelState } from "../../types/pages/roleType";

const initialState: PermissionResponseModelState = {
    permissionResponseModel:{
       status:false,
       message:"",
       result: [],
       
    },
    loading: false,
    responseStatus: 200
};

export const getAllPermissionApi = createAsyncThunk<
AxiosResponse,void,
{
rejectValue:AxiosResponse
}
>(
    "getAllPermission/get",
    async (_arg, thunkApi) => {
            const response = await axios.get(getPermissions, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;
    }
);
export const getAllPermissionSlice = createSlice({
    name: "getAllPermission",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllPermissionApi.pending, (state) => {
            state.loading = true;
          })      
        .addCase(getAllPermissionApi.fulfilled, (state, action) => {
            state.permissionResponseModel = action.payload.data;
            state.loading = false
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }              
        })
        .addCase(getAllPermissionApi.rejected, (state, action) => {
            state.loading = false;
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          }); 
    },
});

export default getAllPermissionSlice.reducer;