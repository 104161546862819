import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
interface portcoState {
    portco : string | null;
}
const initialState: portcoState = {
    portco: "idle"
};
const currentPortcoSlice = createSlice({
    name: 'portco',
    initialState,
    reducers: {
        setActivePortco(state,action : PayloadAction<string>){            
            state.portco =  action.payload;
        }
    },   
    extraReducers : builder => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
    } 
});
export const {setActivePortco} = currentPortcoSlice.actions;
export default currentPortcoSlice.reducer;
