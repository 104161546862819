import { PayloadAction, createSlice } from '@reduxjs/toolkit';
interface portcoState {
    auth : number;
}
const initialState: portcoState = {
    auth: 0
};
const currentAuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthUpdate(state,action : PayloadAction<number>){            
            state.auth =  action.payload;
        }
    },   
});
export const {setAuthUpdate} = currentAuthSlice.actions;
export default currentAuthSlice.reducer;
