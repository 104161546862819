import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useFlashMessage } from '../../../redux/flashMessage/flashMessageHook';
import { DEFAULT_HIDEOUT_TIME } from '../../../utilities/Constants';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FlashMessages: React.FC = () => {
    const {open,message, severity, duration, clearFlashMessage} = useFlashMessage();
  const handleClose = (event?: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }
    clearFlashMessage();
  };

  return (
    <Snackbar 
    open={open} 
    autoHideDuration={duration ?? DEFAULT_HIDEOUT_TIME} 
    onClose={handleClose} 
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default FlashMessages;
