import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getAllRoles } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";



const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    responseStatus: 200
};

export const getRolesApi = createAsyncThunk<
AxiosResponse,void,
{
rejectValue:AxiosResponse
}
>(
    "getRoles/get",
    async (_arg, thunkApi) => {
            var tenantId = localStorage.getItem("tenantId");
            const response = await axios.get(getAllRoles + '/' + tenantId, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;
    }
);

export const getRolesSlice = createSlice({
    name: "getRoles",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getRolesApi.fulfilled, (state, action) => {
            state.responseModel = action.payload.data; 
            state.responseStatus = action.payload.status;                       
        })
        .addCase(getRolesApi.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });        
    },
});

export default getRolesSlice.reducer;
