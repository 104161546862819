import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UploadNewContractState} from "../../types/pages/uploadNewContractTypes";
import axios, {  AxiosResponse } from "axios";
import { uploadNewContract , invokedsAlgorithm} from "../../networking/apiEndpoints";
import { IDsRequest } from "../../types/components/contractDetailsTypes";


const initialState: UploadNewContractState = {
    uploadNewContractResponse:[] ,
    dsAlgoResponse : '',
    responseStatus : 200
};

export const uploadNewContractFile = createAsyncThunk<
AxiosResponse | any,
FormData,
{
    rejectValue: AxiosResponse;
}
>(
    "uploadnewcontract/post",
    async (formData : FormData,thunkApi) => {
        try{
            var token = localStorage.getItem('token');
            const response = await axios.post(uploadNewContract, formData, {
                headers:{"Content-Type": "multipart/form-data",Authorization:`Bearer ${token}` },validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                } 
            return response.data;
        } catch(err){
            console.log(err);
        }
    }
);
export const invokeDsalgorithm = createAsyncThunk(
    "invokedsAlgorithm/post",
    async (requestData : IDsRequest) => {
        try{
            var token = localStorage.getItem('token');
            const response = await axios.post(invokedsAlgorithm, requestData, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
            })
            return response.data;
        } catch(err){
            console.log(err);
        }
    }
);

export const uploadNewContractSlice = createSlice({
    name: "uploadnewcontract",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(uploadNewContractFile.fulfilled, (state, action) => {
            state.uploadNewContractResponse = action.payload;   
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }            
        });        
        builder.addCase(invokeDsalgorithm.fulfilled, (state, action) => {
            state.dsAlgoResponse = action.payload;                        
        });  
    },    
});

export default uploadNewContractSlice.reducer;
