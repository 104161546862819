import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { editContract } from "../../networking/apiEndpoints";
import { UploadNewContractState } from "../../types/pages/uploadNewContractTypes";

const initialState: UploadNewContractState = {
    uploadNewContractResponse:[] ,
    dsAlgoResponse : ''
};

export const saveEditContract = createAsyncThunk(
    "saveEditContract/put",
    async (formData: any) => {
        try{
            var token = localStorage.getItem('token');
            const response = await axios.put(editContract, formData, {
                headers:{"Content-Type": "multipart/form-data", Authorization:`Bearer ${token}`}
            })
            return response.data;
        } catch(err){
            console.log(err);
        }
    }
);

export const saveEditContractSlice = createSlice({
    name: "saveEditContract",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(saveEditContract.fulfilled, (state, action) => {
            state.uploadNewContractResponse = action.payload;                        
        });        
    },
});

export default saveEditContractSlice.reducer;
