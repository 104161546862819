import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { uploadContractDetails } from "../../networking/apiEndpoints";
import { AddContractDetailsState } from "../../types/components/contractDetailsTypes";

const initialState: AddContractDetailsState = {
    addContractDetails: {
        TenantId: "",
        Name: "",
        Description: "",
        ContractTypeId: "",
        CreatedBy: "",
        UpdatedBy: "",
    },
    responseStatus: 200
};

export const addContractDetailsApi = createAsyncThunk<
    AxiosResponse | any,
    FormData,
    {
        rejectValue: AxiosResponse;
    }
>(
    "addcontractdetails/post",
    async (addNewContractDetails, thunkApi) => {
        var token = localStorage.getItem('token');
                   const response = await axios.post(uploadContractDetails, addNewContractDetails, {
                headers: { "Content-Type": "multipart/form-data",Authorization:`Bearer ${token}` },validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
              {
                return thunkApi.rejectWithValue(response);
              }  
            return response.data;
        } 
);

export const addNewContractDetailsSlice = createSlice({
    name: "addcontractdetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addContractDetailsApi.fulfilled, (state, action) => {
            state.addContractDetails = action.payload.data;
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }
        });
    },
});

export default addNewContractDetailsSlice.reducer;
