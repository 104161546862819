import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAssignUser } from "../../types/components/roleModel";


interface AssignUserState {
    users: IAssignUser[];
}

const initialState: AssignUserState = {
    users: [],
};

export const assignUserSlice = createSlice({
    name: "AssignUserSlice",
    initialState,
    reducers : {
        setAssignUsers: (state, action: PayloadAction<IAssignUser[]>) => {
            state.users = action.payload;
        },
    },
});

export const {setAssignUsers} = assignUserSlice.actions;
export default assignUserSlice.reducer;