import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteMultiChildContract } from '../../networking/apiEndpoints';
import axios, { AxiosResponse } from 'axios';

export const deleteChildContractIds = createAsyncThunk<
    AxiosResponse,
    any,
    {
        rejectValue: AxiosResponse;
    }>(
        "deleteChildContractIds/delete",
        async (formData: any, thunkApi) => {
            try {
                var token = localStorage.getItem('token');
                const response = await axios.post(deleteMultiChildContract, formData, {
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                });
                if (response.status === 401) {
                    return thunkApi.rejectWithValue(response);
                }
                return response.data;
            } catch (err) {
                console.log(err);
            }
        }
    );
interface ChildContractDeleteState {
    isDeleteSuccess: boolean;
    deleting: boolean;
    isDeleteError: string | null;
    responseStatus: number;
}

const initialState: ChildContractDeleteState = {
    isDeleteSuccess: false,
    deleting: false,
    isDeleteError: null,
    responseStatus: 200
};

const deleteAttachContractSlice = createSlice({
    name: 'deleteAttachContract',
    initialState: initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteChildContractIds.pending, (state) => {
                state.deleting = true;
                state.isDeleteError = '';
            })
            .addCase(deleteChildContractIds.fulfilled, (state, action) => {
                state.deleting = action.payload.data;
                if (action.payload !== undefined) {
                    state.responseStatus = action.payload.status;
                }
            })
            .addCase(deleteChildContractIds.rejected, (state, action) => {
                state.deleting = false;
                state.isDeleteError = action.error.message ?? '';
                if (action.payload !== undefined) {
                    state.responseStatus = action.payload.status;
                }
            });
    },
});

export default deleteAttachContractSlice.reducer;