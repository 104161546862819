import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { setActiveUserInformation, userInformationType } from "./setUserInfoSlice";

export const useLoggedInUserInformation = () => {
    const {Role, RoleId, TenantId, IsCST, IsRRAS, Id, Name, DisplayName, Email, userImage} = useSelector((state : RootState) => state.userInformation);
    const dispatch = useAppDispatch();
    const setUserInformation = (userInformation : userInformationType) => {
        dispatch(setActiveUserInformation(userInformation))
    }
    return {setUserInformation, Role, RoleId, TenantId, IsCST, IsRRAS, Id, Name, DisplayName, Email, userImage}
} 