import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { ContractDetailsState  } from "../../types/components/contractDetailsTypes";
import axios from "axios";
import { getContractDetails } from "../../networking/apiEndpoints";

const initialState: ContractDetailsState =  {
   contractDetails : {
    contractCreatedBy : '',
     contractCreatedOn : '',
     contractDescription : '',
     contractFieldDetails : [],
     contractId : '',
     contractModifiedBy : '',
    contractModifiedOn : '',
     contractName : '',
     contractTypeName : '',
     isloading : false,
     fileName: '',     
     tenantId : ''
   }
};

export const fetchContractDetails = createAsyncThunk(
    "contractDetails/fetch",
    async (contractid : string) => {
        try{                
            var token = localStorage.getItem('token');
            const response = await axios.get(getContractDetails + "/" + contractid,{
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
              });                                   ;
            return response.data;
        } catch(err){
            console.log(err);
        }
    }
);

export const contractDetailsSlice = createSlice({
    name: "contractdetails",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContractDetails.fulfilled, (state, action) => {
            state.contractDetails = action.payload;
            state.contractDetails.isloading = false;                         
        });     
        builder.addCase(fetchContractDetails.pending, (state, action) => {
            state.contractDetails.isloading = true;                        
        });          
    },
    
});

export default contractDetailsSlice.reducer;
