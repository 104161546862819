import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit"; 
import axios from "axios";
import { deleteEmailTemplate } from "../../../networking/apiEndpoints";

interface DeleteEmailEmailTemplateType {    
  isLoading : boolean;
  isDeleted: boolean;   
  message: string | ""; 
}

const initialState: DeleteEmailEmailTemplateType = {
  isDeleted : false,
  isLoading :false,
  message : "",
};
export const deleteEmailTemplateById  = createAsyncThunk(
    "deleteemailtemplate/delete",
    async (id : string) => {
        try {          
          var token = localStorage.getItem('token');          
          const response = await axios.delete(deleteEmailTemplate+ "/" + id, {
          headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`}
        });              
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  export const deleteEmailTemplateSlice = createSlice({
    name: "deleteemailtemplate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {      
      builder.addCase(deleteEmailTemplateById.fulfilled, (state, action ) => {
        if(action.payload?.status === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else
        {
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
      builder.addCase(deleteEmailTemplateById.pending, (state, action) => {
          state.isLoading = true;
      })
      },
  });
  
  export default deleteEmailTemplateSlice.reducer;