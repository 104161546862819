import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { addFollowUpEmailEndPoint } from "../../networking/apiEndpoints";
import { SaveEmailCreatorResponseState } from "../../types/components/followUpEmailCreator";

const initialState: SaveEmailCreatorResponseState = {
    addResponse: {
        success: false,
        message: '',
        result: null
    },
    loading: false,
    error: null,
    responseStatus: 200
};

// Add 
export const addFollowUpEmailCreator = createAsyncThunk<
AxiosResponse | any,
any,
{
    rejectValue: AxiosResponse;
}>(
    "addFollowUpEmailCreator/post",
    async (model: any, thunkApi) => {
        try {
            var token = localStorage.getItem('token');      
            const response = await axios.post(addFollowUpEmailEndPoint, model , {
                headers: { "Content-Type": "application/json", Authorization:`Bearer ${token}`}
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);
export const addFollowUpEmailCreatorSlice = createSlice({
    name: "addFollowUpEmailCreator",
    initialState,
    reducers:{} ,
    extraReducers: (builder) => {
        builder
            .addCase(addFollowUpEmailCreator.pending, (state, action) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(addFollowUpEmailCreator.fulfilled, (state, action) => {
                state.addResponse = action.payload.data;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
                state.loading = false;
            })
            .addCase(addFollowUpEmailCreator.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export default addFollowUpEmailCreatorSlice.reducer;