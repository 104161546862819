import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getTenantNameById } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";

const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    responseStatus: 200
};

export const getTenantName = createAsyncThunk(
    "fetchTenantName/fetch",
    async (tenantId : string) => {
        try {                      
            const response = await axios.get(getTenantNameById + '/' + tenantId, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${localStorage.getItem('token')}`}
              });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

export const getTenantNameSlice = createSlice({
    name: "tenantName",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getTenantName.fulfilled, (state, action) => {
            state.responseModel = action.payload;                        
        });        
    },
});

export default getTenantNameSlice.reducer;
