import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUserclaims } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";

const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    responseStatus: 200
};

export const userClaims = createAsyncThunk(
    "fetchUserClaims/fetch",
    async (token: string ) => {
        try {           
            const response = await axios.post(getUserclaims,token, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
        });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

export const userClaimSlice = createSlice({
    name: "userclaim",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(userClaims.fulfilled, (state, action) => {
            state.responseModel = action.payload;                        
        });        
    },
});

export default userClaimSlice.reducer;
