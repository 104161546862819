import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { DeleteContract } from "../../types/components/deleteContract";  
import axios from "axios";
import {  deleteContract, deleteMultiContract } from "../../networking/apiEndpoints";


const initialState: DeleteContract = {
  isDeleted : false ,
  isLoading :false   
};

export const deleteContractById  = createAsyncThunk(
    "deletecontract/delete",
    async (id : string) => {
      try {          
        var token = localStorage.getItem('token');
        const response = await axios.delete(deleteContract+ "/" + id,{
          headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
        });              
        return response.status;
      } catch (err) {
        console.log(err);
      }
    }
  );

  export const deleteMultiContracts  = createAsyncThunk(
    "deletecontract/delete",
    async (id : string) => {
      try{
        var token = localStorage.getItem('token');
        const response = await axios.post(deleteMultiContract, id, {
            headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
        })
        return response.data;
    }catch (err) {
        console.log(err);
      }
    }
  );
  
  export const contractDeleteSlice = createSlice({
    name: "deletecontract",
    initialState,
    reducers: {},
    extraReducers: (builder) => {      
      builder.addCase(deleteContractById.fulfilled, (state, action ) => {
        
        if(action.payload === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else{
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
        builder.addCase(deleteContractById.pending, (state, action) => {
            state.isLoading = true;
        })
      },
      

  });
  
  export default contractDeleteSlice.reducer;