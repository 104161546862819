import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getPermissionsByRoleId } from "../../networking/apiEndpoints";
import { PermissionByRoleId, PermissionByRoleIdResponseModelState } from "../../types/pages/roleType";

const permissionState: PermissionByRoleId = {
  id:"",
  name:"",
  description:"",
  permissionCategory:[]
};

const initialState: PermissionByRoleIdResponseModelState = { 
  permissionByRoleIdResponseModel:{
       status:false,
       message:"",
       result: permissionState,
       
    },
    loading: false,
    responseStatus: 200
};


export const getPermissionsByRoleIdApi = createAsyncThunk<
AxiosResponse,
string,
  {
  rejectValue:AxiosResponse
}
>(
    "getPermissionsByRoleId/get",
    async (roleId, thunkApi) => {            
          const response = await axios.get(getPermissionsByRoleId + "/" + roleId, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            })    
              if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }         
              return response;         
    }
);

export const getPermissionsByRoleIdSlice = createSlice({
    name: "getPermissionsByRoleId",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getPermissionsByRoleIdApi.pending, (state) => {
          state.loading = true;
        })
        .addCase(getPermissionsByRoleIdApi.fulfilled, (state, action) => {
          if(action.payload !== undefined)
          state.permissionByRoleIdResponseModel = action.payload.data;
          state.loading = false;
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        }) 
        .addCase(getPermissionsByRoleIdApi.rejected, (state, action) => {
          state.loading = false;
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        });   
      },
});

export default getPermissionsByRoleIdSlice.reducer;
