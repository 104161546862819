import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getPermissionsByRoleId} from "../../networking/apiEndpoints";
import { RolePermissionModelState} from "../../types/components/roleModel";

const initialState: RolePermissionModelState = {
    rolePermissionModel:{
    status:false,
    message:"",
    result: []
 } ,
  loading: false,
  error:null,
  responseStatus: 200
};

export const getRolePermissions = createAsyncThunk<
  AxiosResponse,
  string,
  {
    rejectValue: AxiosResponse; // Specify the type for the rejected value
  }
>('getRolePermissions/get', async (roleId : string, thunkApi) => {
    const response = await axios.get(getPermissionsByRoleId+ "/" + roleId, {
      headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
    }) ;

    if (response.status === 401) {
      // Use rejectWithValue to handle the rejection
      return thunkApi.rejectWithValue(response);
    }

    return response;
});

export const getRolePermissionSlice = createSlice({
  name: "getRolePermissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRolePermissions.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(getRolePermissions.fulfilled, (state, action) => {
      if(action.payload !== undefined)
      state.rolePermissionModel = action.payload.data;
      state.loading = false;
      if(action.payload !== undefined)
        {
          state.responseStatus = action.payload.status;
        }
    }) 
    .addCase(getRolePermissions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if(action.payload !== undefined)
        {
          state.responseStatus = action.payload.status;
        }
    });   
  },
});

export default getRolePermissionSlice.reducer;