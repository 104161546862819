import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { setAuthUpdate } from "./setAuthSlice";

export const useAuthSetting = () => {
    const {auth} = useSelector((state : RootState) => state.currentAuth);
    const dispatch = useAppDispatch();
    const setAuth = (auth: number) => {
        dispatch(setAuthUpdate(auth))
    }
    return {setAuth, auth}
} 