import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {getGeneratedEmailAndSummaryText} from "../../../networking/apiEndpoints";
import { EmailSummaryGenerationResponseState } from "../../../types/components/rass/emailSummaryGenModels";
import { EmailSummaryGenReqModel } from "../../../types/components/rass/emailSummaryGenReqModel";

const initialState: EmailSummaryGenerationResponseState = {
  emailSummaryGenResponseModel:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchEmailSummaryGeneratedText = createAsyncThunk<
  any,
  EmailSummaryGenReqModel,
  {
    rejectValue: AxiosResponse;
  }
>(
  "emailsummarygeneratedtext/fetch", 
  async ( emailSummaryGenReqModel  : EmailSummaryGenReqModel, thunkApi) => {    
    var token = localStorage.getItem('token');    
    const response = await axios.post(`${getGeneratedEmailAndSummaryText}`, emailSummaryGenReqModel, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },      
        validateStatus: function (status) {
          return status < 500;
        },
      });
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }          
      return response.data;    
  }
);
export const getEmailSummaryGeneratedTextSlice = createSlice({
  name: "emailsummarygeneratedtext",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailSummaryGeneratedText.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchEmailSummaryGeneratedText.fulfilled, (state, action) => {    
      state.emailSummaryGenResponseModel = action.payload;
      state.loading = false;
    }) 
    .addCase(fetchEmailSummaryGeneratedText.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default getEmailSummaryGeneratedTextSlice.reducer;
