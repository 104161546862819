import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import { displayREGModuleMessage } from "./regModuleMessageSlice";

export const useREGModuleMessage = () => {
    const {mesageOpen, firstTimeOpen} = useSelector((state : RootState) => state.regModuleMessage);
    const dispatch = useAppDispatch();
    const showREGModuleMessage = () => {
        dispatch(displayREGModuleMessage({mesageOpen: true, firstTimeOpen: true}))
    }
    const closeREGModuleMessagePopup = () => {
        dispatch(displayREGModuleMessage({mesageOpen:false, firstTimeOpen: true}))
    }
    const clearREGModule = () => {
        dispatch(displayREGModuleMessage({mesageOpen:false, firstTimeOpen: false}))
    }
    return {showREGModuleMessage, closeREGModuleMessagePopup, clearREGModule, mesageOpen, firstTimeOpen}
} 