import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { changePassword } from "../../networking/apiEndpoints";
import { ChangePassowdModelState} from "../../types/components/passwordResetModel";

const initialState: ChangePassowdModelState = {
    changePasswordModel: {
        Status: undefined,
        Message: '',
        Result: undefined
    },
    loading: false,
    error: null,
    showAlert: false
};

// Reset password
export const changePasswordAsyn = createAsyncThunk(
    "changePassword/post",
    async (formData: any) => {
        try {
            const response = await axios.post(changePassword, formData, {
                headers: { "Content-Type": "application/json" }
            })
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);
export const changePasswordAsynSlice = createSlice({
    name: "passwordChange",
    initialState,
    reducers: {
        hideAlert(state) {
            state.showAlert = false;
        },
        showAlert(state) {
            state.showAlert = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(changePasswordAsyn.pending, (state, action) => {
                state.loading = true;
                state.error = '';
                state.showAlert = false;
            })
            .addCase(changePasswordAsyn.fulfilled, (state, action) => {
                state.changePasswordModel = action.payload;
                state.loading = false;
                state.showAlert = true;
            })
            .addCase(changePasswordAsyn.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                state.showAlert = false;
            });
    },
});
export const { showAlert, hideAlert } = changePasswordAsynSlice.actions;
export default changePasswordAsynSlice.reducer;