import { RouteObject } from "react-router-dom";
import React, { lazy } from "react";

const ContractList = lazy(() => import("../pages/cst/ContractList"));
const ContractTypeList = lazy(() => import("../pages/cst/ContractType/List"));
const ContractTermList = lazy(() => import("../pages/cst/ContractTermList"));
const ContractDetail = lazy(
  () => import("../pages/cst/ContractList/contractdetail")
);
const UploadNewContract = lazy(() => import("../pages/cst/UploadNewContract"));
const FeedbackLists = lazy(() => import("../pages/cst/Feedback/Index"));
const ContractReports = lazy(
  () => import("../pages/cst/ContractReports/Index")
);
const AddContractType = lazy(
  () => import("../pages/cst/ContractType/AddContractType")
);
const AddContractTerm = lazy(
  () => import("../components/cst/ContractTerms/addcontractterm")
);
const EditContractType = lazy(
  () => import("../pages/cst/ContractType/EditContractType")
);
const EditContractTerm = lazy(
  () => import("../components/cst/ContractTerms/editcontractterm")
);
const EditUploadedContract = lazy(
  () => import("../pages/cst/EditUploadedContract")
);
const ChildContractList = lazy(() => import("../pages/cst/AttachContract"));

const cstRoutes: RouteObject[] = [
  { path: "/", element: <ContractList /> },
  { path: "/contract-types", element: <ContractTypeList /> },
  { path: "/contract-terms", element: <ContractTermList /> },
  { path: "/contractdetails", element: <ContractDetail /> },
  { path: "/uploadnewcontract", element: <UploadNewContract /> },
  { path: "/users-feedback", element: <FeedbackLists /> },
  { path: "/contract-reports", element: <ContractReports /> },
  {
    path: "/addcontracttype",
    Component: () => <AddContractType mode="add" itemId={""}></AddContractType>,
  },
  {
    path: "/addcontractterm",
    Component: () => (
      <AddContractTerm
        mode="add"
        itemId={""}
        isExpanded={false}
      ></AddContractTerm>
    ),
  },
  {
    path: "/editcontracttype/:id",
    Component: () => <EditContractType></EditContractType>,
  },
  {
    path: "/editcontractterm/:id",
    Component: () => <EditContractTerm></EditContractTerm>,
  },
  { path: "/edituploadedcontract", element: <EditUploadedContract /> },
  { path: "/getchildcontractlist", element: <ChildContractList /> },
];

export default cstRoutes;
