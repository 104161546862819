import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {getDepartmentList} from "../../../networking/apiEndpoints";
import { DepartmentResponseState } from "../../../types/components/rass/departmentModel";

const initialState: DepartmentResponseState = {
  departmentResponseModel:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchDepartmentList = createAsyncThunk<
  any,
  "",
  {
    rejectValue: AxiosResponse;
  }
  >("departmentlist/fetch", 
  async (input :string, thunkApi) => {
    var token = localStorage.getItem('token');  
    const response = await axios.get(getDepartmentList ,{
      headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
    });
    if(response.status === 401)
      {
        return thunkApi.rejectWithValue(response);
      }   
  return response.data;    
  }
);
export const getDepartmentListSlice = createSlice({
  name: "departmentslice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepartmentList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchDepartmentList.fulfilled, (state, action) => {    
      state.departmentResponseModel = action.payload;      
      state.loading = false;
    }) 
  },
});

export default getDepartmentListSlice.reducer;
