import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabData {
  label: string;
  content?: React.ReactNode;
  route?: string | undefined;
}

interface TabsProps {
  tabs: TabData[];
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  onTabClick?: (index: number, route: string | undefined) => void;
  cssStyle?: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function getTabIndexProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function TabsComponent({
  tabs,
  value,
  onChange,
  onTabClick,
  cssStyle,
}: TabsProps) {
  const handleTabClick = (index: number, route: string | undefined) => {
    if (onTabClick) {
      onTabClick(index, route);
    }
  };
  return (
    <Box sx={{ widht: "100%" }} className={cssStyle}>
      <Tabs value={value} onChange={onChange}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            onClick={() => handleTabClick(index, tab.route)}
            {...getTabIndexProps(index)}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
