import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { DeleteContract } from "../../types/components/deleteContract";  
import axios from "axios";
import {  deleteContractType } from "../../networking/apiEndpoints";
import { DeleteContractType } from "../../types/components/deleteContractType";


const initialState: DeleteContractType = {
  isDeleted : false ,
  isLoading :false   
};

export const deleteContractTypeById  = createAsyncThunk(
    "deletecontracttype/delete",
    async (id : string) => {
        try {          
          var token = localStorage.getItem('token');
          var tenantId = localStorage.getItem('tenantId');
          const response = await axios.delete(deleteContractType+ "/" + id + "/" + tenantId, {
          headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`}
        });              
        return response.status;
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  export const contractDeleteSlice = createSlice({
    name: "deletecontracttype",
    initialState,
    reducers: {},
    extraReducers: (builder) => {      
      builder.addCase(deleteContractTypeById.fulfilled, (state, action ) => {
        
        if(action.payload === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else{
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
        builder.addCase(deleteContractTypeById.pending, (state, action) => {
            state.isLoading = true;
        })
      },
      

  });
  
  export default contractDeleteSlice.reducer;