import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import {
  AppBar,
  Toolbar,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import { SyntheticEvent, useEffect } from "react";
import { useState } from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import SelectBox from "../../components/cst/SelectBox";
import { persistor, useAppDispatch, useAppSelector } from "../../redux/store";
import {
  ResponseWithSelectedModelState,
  selectedTenant,
} from "../../redux/features/getAllTenantSlice";
import React from "react";
import * as icons from "@mui/icons-material";
import {
  Contracts,
  FollowUpEmailCreator,
  userProfileMenu,
} from "../../utilities/Constants";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TabsComponent from "../../components/Tabs";
import {
  systemAdminRoleName,
} from "../../utilities/Constants";
import { setActiveMenuItem } from "../../redux/features/getMenuSlice";
import { useModuleSwitcher } from "../../redux/features/moduleSwitcher/moduleSwicherHook";
import { usePermissionNavList } from "../LeftNav/permissionNavHook";
import { useREGModuleMessage } from "../../redux/features/rras/regModuleMessage/regModuleMessageHook";
import { useAuthSetting } from "../../redux/UserSlice/setAuthHook";
import { useLoggedInUserInformation } from "../../redux/UserSlice/setUserInfoHook";
import { getIsCST, getIsRRAS } from "../../utilities";

interface userRoleDataInterface {
  status?: boolean;
  result?: Array<string>;
  message?: string;
}
interface userInfoInterface {
  Name?: string;
  Role?: string;
  Permissions?: string;
  DisplayName?: string;
}
interface allTenantDataResultInterface {
  id: string;
  name: string;
  isCST?: boolean;
  isRRAS?: boolean;
}
interface allTenantDataInterface {
  status?: boolean;
  result: Array<allTenantDataResultInterface>;
  message?: string;
}
interface SinglePortco {
  id: string;
  name: string;
}
export default function MenuAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const {clearREGModule} = useREGModuleMessage();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {setAuth} =  useAuthSetting();
  const {Role} = useLoggedInUserInformation();
  const [userInfo, setUserInfo] = useState<userInfoInterface>();
  const [userName, setUserName] = useState<string>();
  const [userRoles, setUserRole] = useState<string>();
  const [getSelectedTenant, setGetSelectedTenant] = useState<any>();
  const {setActiveTab, activeTabIndex} = useModuleSwitcher();
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  };

  const [leftMargin, setLeftMargin] = useState("");
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleMenuItemClick = (profileMenu: any, onClick: any) => {
    if (profileMenu == "Logout") {
      persistor.purge();
      clearREGModule();
      setAuth(0);
      localStorage.clear();
      sessionStorage.clear(); 
      navigate("/");    
      return;
    } else if (profileMenu == "My Profile") {
      navigate("/profile");
    } else if (profileMenu == "Change Password") {
      navigate("/change-password");
    }
    handleCloseUserMenu();
  };
  const { LeftNavListREG, LeftNavListCST, navigateToVisibleItem } = usePermissionNavList();
  useEffect(() => {
    if (localStorage.getItem("userInfo") != undefined) {
      const userInfoString = JSON.parse(
        localStorage.getItem("userInfo") ?? ""
      ) as userInfoInterface;
      setUserInfo(
        JSON.parse(localStorage.getItem("userInfo") ?? "") as userInfoInterface
      );
      setUserName(userInfoString?.DisplayName);
      setUserRole(userInfoString?.Role);
    }
  }, []);

  const allTenant: ResponseWithSelectedModelState = useAppSelector((state) => {
    return state.getAllTenant;
  });
  const allTenantData = allTenant?.responseModel as allTenantDataInterface;
  let allTenantListData = allTenantData.result as SinglePortco[];
  if (allTenantData.result) {
    allTenantListData = allTenantListData.filter(function (
      val: allTenantDataResultInterface
    ) {
      return val.name.trim() !== "";
    });
    allTenantListData.sort((a: SinglePortco, b: SinglePortco) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }
  const loc = useLocation();
  useEffect(() => {
    document.querySelector("body")?.classList.remove("menu-active");
  }, [loc]);

  useEffect(() => {
    if (loc.pathname === "/contractdetails") {
      setLeftMargin("tab-margin");
    } else {
      setLeftMargin("");
    }
  }, [loc]);
  const toggleMenus = () => {
    document.querySelector("body")?.classList.toggle("menu-active");
  };
  const [selectedPortco, setSelectedPortco] = useState<string>(
    allTenant.selectedTenant
  );
  const [portcoName, setPortcoName] = useState<string>("");
  useEffect(() => {
    if (
      userRoles &&
      [systemAdminRoleName].indexOf(userRoles)
    ) {
      setSelectedPortco(allTenant.selectedTenant);
    }
  }, []);

  const handleChange = (tenantId: string) => {
    setSelectedPortco(tenantId);
    dispatch(selectedTenant(tenantId));
    localStorage.setItem("tenantId", tenantId);
    handleSelectedTenant();
    const getNewSelection = allTenantData.result.find(
      (el) => el.id === localStorage.getItem("tenantId")
    );
    if (getNewSelection?.isRRAS && !getNewSelection?.isCST) {
      navigateToVisibleItem(LeftNavListREG);
      setActiveTab(1);
    } else {
      navigateToVisibleItem(LeftNavListCST);
      setActiveTab(0);
    }
  };

  const handleSelectedTenant = () => {
    if (allTenantData.result !== null) {
      const tenant = allTenantData.result.find(
        (el) => el.id === localStorage.getItem("tenantId")
      );
      setGetSelectedTenant(tenant);
    }
  };

  useEffect(() => {
    handleSelectedTenant();
  }, [getSelectedTenant]);

  const handleTabClick = (index: number, route?: string) => {
    if (index === 0) {
      navigateToVisibleItem(LeftNavListCST);
      setActiveTab(0);
    } else if (index === 1) {
      navigateToVisibleItem(LeftNavListREG);
      setActiveTab(1);
    }
  };
  const isTabsComponentDisplay = (): boolean =>{
    let userPermissionsIsCST = getIsCST();
    let userPermissionsIsRRAS = getIsRRAS();
    return userPermissionsIsCST && userPermissionsIsRRAS && getSelectedTenant?.isCST && getSelectedTenant?.isRRAS;
  };
  const routePortcoInfo = useLocation();

  useEffect(() => {
    if (Role === systemAdminRoleName){
      if (routePortcoInfo.pathname === "/rras-follow-up-email-creator") {
        dispatch(setActiveMenuItem(FollowUpEmailCreator));
      } else if (routePortcoInfo.pathname === "/") {
        dispatch(setActiveMenuItem(Contracts));
      }
    }
  }, [portcoName]);

  const tabs = [
    { label: "CST", route: "/" },
    { label: "REG", route: "/rras-follow-up-email-creator" },
  ];
  return (
    <Box sx={{ display: "flex" }} className="topNavContainer">
      <AppBar className="app-bar" component="nav" position="fixed" color="inherit" elevation={2}>
        <Toolbar sx={{ justifyContent: "end" }}>
          <Button
            className="menuToggle"
            onClick={toggleMenus}
            aria-label="Menu Toggle"
          >
            <span className="menuIcon" aria-hidden="true"></span>
          </Button>
              <div className="top-nav-tab">
                {isTabsComponentDisplay() && (
                  <TabsComponent
                    tabs={tabs}
                    value={activeTabIndex}
                    onChange={handleTabChange}
                    onTabClick={handleTabClick}
                    cssStyle={leftMargin}
                  ></TabsComponent>
                )}
              </div>
          {userRoles &&
            [systemAdminRoleName].indexOf(userRoles) !==
              -1 && (
              <FormControl
                size="small"
                className="portco-drodown headerdrop-width"
              >
                <InputLabel>Select Portco</InputLabel>
                <SelectBox
                  label="Select Portco *"
                  value={selectedPortco}
                  ListData={allTenantListData}
                  onChange={(e) => {
                    handleChange(e.target.value);
                    setPortcoName(e.target.value);
                  }}
                  error={false}
                  errorText="test"
                  disabled={false}
                  displayEmpty={false}
                  disableUnderLine={true}
                  variant={true}
                  selectBydefaultChoosen={false}
                />
              </FormControl>
            )}

          <div className="topnav-profileimg">
            <Avatar
              aria-label="Profile Image"
              className=""
              src="/static/images/avatar/2.jpg"
            />
          </div>
          <div className="userDetails">
            <Typography variant="subtitle2" noWrap className="userNameWrap">
              {userName}
            </Typography>
            <Typography variant="body2" noWrap className="userRoleWrap">
              {userRoles}
            </Typography>
          </div>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <icons.ExpandMoreOutlined />
          </IconButton>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {userProfileMenu.map((profileMenu: any) => (
              <MenuItem
                key={profileMenu}
                onClick={() =>
                  handleMenuItemClick(profileMenu, profileMenu.onClick)
                }
              >
                {profileMenu == "My Profile" && (
                  <Person2OutlinedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                )}
                {profileMenu == "Change Password" && (
                  <WarningAmberRoundedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                )}
                {profileMenu == "Logout" && (
                  <LockOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
                )}
                <Typography textAlign="center">{profileMenu}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
