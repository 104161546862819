import React, { lazy } from "react";
import { RouteObject } from "react-router-dom";

const SummaryTemplateList = lazy(
  () => import("../pages/rras/SummaryTemplate/Index")
);
const AddSummaryTemplate = lazy(() =>
  import("../components/rras/SummaryTemplate/addSummaryTemplate").then(
    (module) => ({ default: module.AddSummaryTemplate })
  )
);
const EditSummaryTemplate = lazy(() =>
  import("../components/rras/SummaryTemplate/editSummaryTemplate").then(
    (module) => ({ default: module.EditSummaryTemplate })
  )
);
const EmailTemplateListPage = lazy(() => import("../pages/rras/emailTemplate"));
const AddEmailTemplate = lazy(() =>
  import("../components/rras/EmailTemplate/addEmailTemplate").then(
    (module) => ({ default: module.AddEmailTemplate })
  )
);
const EditEmailTemplate = lazy(() =>
  import("../components/rras/EmailTemplate/editEmailTemplate").then(
    (module) => ({ default: module.EditEmailTemplate })
  )
);
const ViewEmail = lazy(
  () => import("../components/rras/CreateEmail/ViewEmail")
);
const CreateNewEmail = lazy(
  () => import("../components/rras/CreateEmail/CreateEmail")
);
const RrasUserList = lazy(() => import("../pages/rras/rrasUserList"));

const EmailCreatorList = lazy(() =>
  import("../pages/rras/EmailCreator/Index").then((module) => ({
    default: module.EmailCreatorList,
  }))
);

const rrasRoutes: RouteObject[] = [
  { path: "/rras-follow-Up-email-creator", element: <EmailCreatorList /> },
  { path: "/rras-summary-templates", element: <SummaryTemplateList /> },
  { path: "/rras-add-summary-template", element: <AddSummaryTemplate /> },
  { path: "/rras-edit-summary-template/:id", element: <EditSummaryTemplate /> },
  { path: "/rras-email-templates", element: <EmailTemplateListPage /> },
  { path: "/rras-add-email-template", element: <AddEmailTemplate /> },
  { path: "/rras-edit-email-template/:id", element: <EditEmailTemplate /> },
  {
    path: "/rras-view-email/:id",
    Component: () => <ViewEmail mode="view"></ViewEmail>,
  },
  {
    path: "/rras-create-new-email",
    Component: () => <CreateNewEmail mode="add"></CreateNewEmail>,
  },
  { path: "/rras-users", element: <RrasUserList /> },
];

export default rrasRoutes;
