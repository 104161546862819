import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getContractDetailsAttachments } from "../../networking/apiEndpoints";
import { UserListModelState } from "../../types/components/userModel";
import { NoAttachments } from "../../utilities/Constants";



const initialState: UserListModelState = {
    userListModel:{
      status:false,
      message:"",
      result: []    
   } ,
    loading: false,
    error:null,
    responseStatus: 200
  };


export const getContractDetailsAttachmentsApi = createAsyncThunk<
AxiosResponse,
string,
  {
  rejectValue:AxiosResponse
}
>(
    "getContractDetailsAttachments/get",
    async (contractId, thunkApi) => {   
        var tenantId = localStorage.getItem('tenantId');         
          const response = await axios.get(getContractDetailsAttachments + "/" + contractId + "/" + tenantId, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            })    
              if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }         
              return response;         
    }
);

export const getContractDetailsAttachmentsSlice = createSlice({
    name: "getContractDetailsAttachments",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getContractDetailsAttachmentsApi.pending, (state) => {
          state.loading = true;
          state.error = '';
        })
        .addCase(getContractDetailsAttachmentsApi.fulfilled, (state, action) => {
          if(action.payload !== undefined)
          state.userListModel = action.payload.data;
          state.loading = false;
          if(action.payload.data.message === NoAttachments)
            {
                state.userListModel.result =[];
            }
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        }) 
        .addCase(getContractDetailsAttachmentsApi.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message ?? '';
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        });   
      },
});

export default getContractDetailsAttachmentsSlice.reducer;
