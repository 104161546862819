import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getLLMMethods } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";

const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: []
    } ,
    responseStatus: 200
};

export const getLLMMethodsApi = createAsyncThunk<
AxiosResponse,void,
{
rejectValue:AxiosResponse
}
>(
    "fetchLLMMethods/fetch",
    async (_arg, thunkApi) => {                     
            const response = await axios.get(getLLMMethods, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
              });
              if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;
    }
);

export const getLLMMethodsSlice = createSlice({
    name: "getLLMMethods",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getLLMMethodsApi.fulfilled, (state, action) => {
            state.responseModel = action.payload.data;
            state.responseStatus = action.payload.status;                     
        })
        .addCase(getLLMMethodsApi.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });       
    },
});

export default getLLMMethodsSlice.reducer;