import React from "react";
import { Button as MuiButton } from "@mui/material";


enum VariantTypes {
    text = "text",
    outLined = "outlined",
    contained = "contained"
}

const CustomButton = MuiButton;
interface ButtonProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    text: string;
    intent?: string;
    disabled?: boolean;
    variant?: VariantTypes;
    type?: string;
    onKeyPress?: React.KeyboardEventHandler<HTMLButtonElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
    className?: string;
    height?: number;
    width?: number;
    autoFocus?: boolean | false;
    iconWithButton?: string;
    tabIndex?: number | 0;
    disableRipple?: boolean | false;
    startIcon?: React.ReactNode;
    backgroundColor?: string;
    color?: string;
}

const Button = (props: ButtonProps) => {
    return (
        <CSTButton {...props}></CSTButton>
    );
};

const CSTButton = (props: ButtonProps) => {
    const variantValue: VariantTypes = GetVariantValue(props);
return (
    <CustomButton tabIndex={props.tabIndex}
    className={props.className}
    variant={variantValue ? variantValue : "contained"}
    sx={{
width:props.width,
height: props.height
    }}
    onClick={props.onClick}
    disabled={props.disabled ? true : false}
    startIcon={props.startIcon}
    >
        {props.iconWithButton && <i className={props.iconWithButton}></i>}
        {props.text}
    </CustomButton>
);
};
function GetVariantValue(props: ButtonProps)
{
    if (props.type === VariantTypes.text) {
        return VariantTypes.text;
      }
      else if(props.type === VariantTypes.outLined) {
        return VariantTypes.outLined;
      }
      return VariantTypes.contained;
}
export default Button;