import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {  getAllUsers, getUserDetails} from "../../networking/apiEndpoints";
import { UserListModelState } from "../../types/components/userModel";

const initialState: UserListModelState = {
  userListModel:{
    status:false,
    message:"",
    result: []    
 } ,
  loading: false,
  error:null,
  responseStatus: 200
};
export const fetchUserDetail = createAsyncThunk<
  AxiosResponse,
  string,
  {
    rejectValue: AxiosResponse; // Specify the type for the rejected value
  }
>('userList/fetch', async (id, thunkApi) => {
    const response = await axios.get(getUserDetails+ "/" + id, {
      headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
    }) ;

    if (response.status === 401) {
      // Use rejectWithValue to handle the rejection
      return thunkApi.rejectWithValue(response);
    }

    return response;
});

export const fetchUserList = createAsyncThunk<
  AxiosResponse,
  string,
  {
    rejectValue: AxiosResponse; // Specify the type for the rejected value
  }
>('userList/fetch', async (arg, thunkApi) => {
  try {
    const response = await axios.get(getAllUsers, {
      headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
    }) ;

    if (response.status === 401) {
      // Use rejectWithValue to handle the rejection
      return thunkApi.rejectWithValue(response);
    }

    return response.data;
  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to trigger the rejection
  }
});




export const userListSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchUserList.fulfilled, (state, action) => {
      if(action.payload !== undefined)
      state.userListModel = action.payload.data;
      state.loading = false;
      if(action.payload !== undefined)
        {
          state.responseStatus = action.payload.status;
        }
    }) 
    .addCase(fetchUserList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if(action.payload !== undefined)
        {
          state.responseStatus = action.payload.status;
        }
    });   
  },
});

export default userListSlice.reducer;
