import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { Divider } from "@mui/material";
import LeftNavListWrap from "./leftNavListWrap";
import { useLocation } from "react-router-dom";
import { ResponseWithSelectedModelState } from "../../redux/features/getAllTenantSlice";
import { useAppSelector } from "../../redux/store";
import { useModuleSwitcher } from "../../redux/features/moduleSwitcher/moduleSwicherHook";
import { usePermissionNavList } from "./permissionNavHook";
import DialogWithTwoButton from "../../components/cst/DialogWithTwoButton";
import { useREGModuleMessage } from "../../redux/features/rras/regModuleMessage/regModuleMessageHook";
import { RegModuleMessage } from "../../utilities/Constants";

const drawerWidth = "100%";
interface userInfoInterface {
  Name?: string;
  Role?: string;
  Permissions?: string;
  DisplayName?: string;
}
const LeftNav = () => {
  const [selectedIndex, setSelectedIndex] = useState<string>(
    "ContractManagement_0"
  );
  const {mesageOpen, showREGModuleMessage, firstTimeOpen, closeREGModuleMessagePopup} = useREGModuleMessage();
  const {LeftNavListREG, LeftNavListCST} = usePermissionNavList();
  const { activeTabIndex } = useModuleSwitcher();
  const path = useLocation();
  const containRras = (path: any): boolean => {
    let rrasEnable = activeTabIndex;
    if (path.pathname.includes("/rras")) {
      return true;
    }
    else if (rrasEnable === 1) {
      return true;
    }
    else {
      return path.search.includes("rass");
    };
  };
  
  useEffect(() => {
    if(containRras(path) && !firstTimeOpen){
      showREGModuleMessage();
    }
  },[path])

  const useInfo = JSON.parse(
    localStorage.getItem("userInfo") ?? JSON.stringify({ Name: "", Role: "", Permissions: "", DisplayName: "" })
  ) as userInfoInterface;
  const userRoles = useInfo?.Role;

  const drawer = (
    <div className="leftNavContainer">
      <Toolbar className="logo">
        <img
          className="appLogoWrapper"
          src={`${process.env.PUBLIC_URL}/riverside-company-logo.svg`}
          alt="App Logo"
        />
      </Toolbar>
      <Divider />
        <LeftNavListWrap
          leftNavList={containRras(path) ? LeftNavListREG : LeftNavListCST}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
    </div>
  );

  return (
    <>
    <Box sx={{ display: "flex" }}>
      <Drawer
        className="drawer-container"
        PaperProps={{
          elevation: 3,
        }}
        variant="permanent"
        sx={{
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
    {mesageOpen && <DialogWithTwoButton
        dialogOpen={true}
        message={RegModuleMessage}
        yesBtnClick={() => {closeREGModuleMessagePopup()}}
        dialogClose={() => {}}
        buttonOneText="OK"
        buttonOneType="outlined"
        isCloseShow = {false}
        className = {"reg-module-message-popup"}
      />}
    </>
  );
}

export default LeftNav;
