import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createUser } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";
import { CreateUserInterface } from "../../types/pages/createUserType";



const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    responseStatus: 200
};

export const createUserApi = createAsyncThunk(
    "createUser/post",
    async (createUserData : CreateUserInterface) => {
        var token = localStorage.getItem('token');
        try{
            const response = await axios.post(createUser,createUserData, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
            })
            return response.data;
        } catch(err){
            console.log(err);
        }
    }
);

export const createUserSlice = createSlice({
    name: "createUser",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(createUserApi.fulfilled, (state, action) => {
            state.responseModel = action.payload;                        
        });        
    },
});

export default createUserSlice.reducer;
