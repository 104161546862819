import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { savePermissions } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";
import { SavePermissionsInterface } from "../../types/pages/roleType";

const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    responseStatus: 200
};
export const savePermissionApi = createAsyncThunk<
AxiosResponse,SavePermissionsInterface,
{
rejectValue:AxiosResponse
}
>(
    "savePermission/post",
    async (savePermission, thunkApi) => {
        var token = localStorage.getItem('token');     
            const response = await axios.post(savePermissions,savePermission, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;    
    }
);

export const savePermissionSlice = createSlice({
    name: "savePermission",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(savePermissionApi.fulfilled, (state, action) => {
            state.responseModel = action.payload.data;                        
        }) 
        .addCase(savePermissionApi.rejected, (state, action) => {           
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });       
    },
});

export default savePermissionSlice.reducer;