import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IModuleSwitcher {
    activeTabIndex : number,
}

const initialState : IModuleSwitcher = {
    activeTabIndex : 0,
}

const moduleSwitcherSlice = createSlice({
    name : "moduleSwitcher",
    initialState,
    reducers : {
        displaySelectedTab : (state : IModuleSwitcher, action : PayloadAction<IModuleSwitcher>) => {
            state.activeTabIndex = action.payload.activeTabIndex;
        }
    }
})
export const {displaySelectedTab} = moduleSwitcherSlice.actions;
export default moduleSwitcherSlice.reducer