import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { addEditContractTypes } from "../../networking/apiEndpoints";
import { ContractTypeModelState } from "../../types/components/contractTypeModel";

const initialState: ContractTypeModelState = {
    contractTypeModel: [],
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
};

// Add/Edit Contract Type
export const addEditContractType = createAsyncThunk<
AxiosResponse | any,
any,
{
    rejectValue: AxiosResponse;
}>(
    "addEditContractType/post",
    async (formData: any, thunkApi) => {
        try {
            var token = localStorage.getItem('token');
            var tenantId = localStorage.getItem('tenantId');
            formData.tenantId = tenantId;
            const response = await axios.post(addEditContractTypes, formData, {
                headers: { "Content-Type": "application/json", Authorization:`Bearer ${token}`}
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);
export const addEditContractTypeSlice = createSlice({
    name: "addEditContractType",
    initialState,
    reducers: {
        hideAlert(state) {
            state.showAlert = false;
        },
        showAlert(state) {
            state.showAlert = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEditContractType.pending, (state, action) => {
                state.loading = true;
                state.error = '';
                state.showAlert = false;
            })
            .addCase(addEditContractType.fulfilled, (state, action) => {
                state.contractTypeModel = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
                state.loading = false;
                state.showAlert = true;
            })
            .addCase(addEditContractType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                state.showAlert = false;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export const { showAlert, hideAlert } = addEditContractTypeSlice.actions;
export default addEditContractTypeSlice.reducer;