import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getAllTenants } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";

export interface ResponseWithSelectedModelState extends ResponseModelState {
    selectedTenant: string
    responseStatus: number
}

const initialState: ResponseWithSelectedModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null,
       
    } ,
    selectedTenant: "",
    responseStatus: 200
};

export const getAllTenantApi = createAsyncThunk<
AxiosResponse,void,
{
rejectValue:AxiosResponse
}
>(
    "getAllTenant/get",
    async (_arg, thunkApi) => {
            const response = await axios.get(getAllTenants, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;
    }
);

export const getAllTenantSlice = createSlice({
    name: "getAllTenant",
    initialState,
    reducers: {
        selectedTenant (state, action) {
            state.selectedTenant = action.payload;
        }       
    },
    extraReducers: (builder) => {
        builder.addCase(getAllTenantApi.fulfilled, (state, action) => {
            state.responseModel = action.payload.data;
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }              
        })
        .addCase(getAllTenantApi.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          }); 
    },
});
export const {selectedTenant} = getAllTenantSlice.actions;
export default getAllTenantSlice.reducer;
