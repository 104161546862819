import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { emailCheck } from '../../networking/apiEndpoints';

export const isEmailExists = createAsyncThunk(
    'isemailexists',
    async (email: string) => {
        try {  
            const response = await axios.get(emailCheck+'?email='+ email.trim());
            return response.data.status;
        } catch (error) {
            throw error;
        }
    }
);
const isEmailExistsSlice = createSlice({
    name: 'isEmailExists',
    initialState: {
        emailExists: false,
        isLoading: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(isEmailExists.fulfilled, (state, action) => {
            state.isLoading = false;
            state.emailExists = action.payload;
        });
        builder.addCase(isEmailExists.pending, (state, action) => {
            state.isLoading = true;
        });
    },
});
export default isEmailExistsSlice.reducer;
