import { jwtDecode, JwtPayload } from "jwt-decode";

interface CustomJwtPayload extends JwtPayload {
  Permissions?: string[];
}

export const getPermissionsFromToken = (): string[] => {
  try {
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode<CustomJwtPayload>(token ?? "");
    return decodedToken.Permissions || [];
  } catch (error) {
    console.log('Invalid token:' + error);
    return [];
  }
};