import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getContractTypes } from "../../networking/apiEndpoints";
import { ContractTypeModel, ContractTypeModelState } from "../../types/components/contractTypeModel";

export const fetchContractTypeFilterList = createAsyncThunk(
    "contractTypeModel/fetch",
    async (contractTypeName: string, thunkApi) => {        
            var token = localStorage.getItem('token');
            var tenantId = localStorage.getItem('tenantId');
            const data = `${contractTypeName === undefined ? '%20' : contractTypeName === '' ? '%20' : contractTypeName}`;
        const response = await axios.get(getContractTypes + "/filter/" + tenantId + "/" + data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    tenantId: tenantId,
                },
            });
            // You can use the status as needed (e.g., handle different status codes)
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }   

            return response.data;
        
    }
);


interface ContractTypeFilterState {
    data: ContractTypeModel[] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus:number;
}

const initialState: ContractTypeFilterState = {
    data: [],
    loading: false,
    error: null,
    responseStatus: 200
};

export const ContractTypeFilterListSlice = createSlice({
    name: "contractTypelist",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContractTypeFilterList.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchContractTypeFilterList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(fetchContractTypeFilterList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
            });
    },
});
export default ContractTypeFilterListSlice.reducer;