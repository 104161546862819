import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import ThemeProviderWrapper from './theme';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
console.log("Current Environment: ", process.env.NODE_ENV);
root.render(
  process.env.NODE_ENV === 'development' ? (
    <React.StrictMode>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProviderWrapper>
          <App />
        </ThemeProviderWrapper>
      </PersistGate>
      </Provider>
    </React.StrictMode>
  ) : (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProviderWrapper>
          <App />
        </ThemeProviderWrapper>
      </PersistGate>
    </Provider>
  )
);
