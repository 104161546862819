import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {getGeneratedEmailText} from "../../../networking/apiEndpoints";
import { EmailTextGenerationResponseState  } from "../../../types/components/rass/emailTextGenModels";
import { EmailTextGenReqModel } from "../../../types/components/rass/emailTextGenReqModel";

const initialState: EmailTextGenerationResponseState = {
  emailTextGenResponseModel:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchEmailGeneratedText = createAsyncThunk<
  any,
  EmailTextGenReqModel,
  {
    rejectValue: AxiosResponse;
  }
>(
  "emailgeneratedtext/fetch", 
  async ( emailTextGenReqModel  : EmailTextGenReqModel, thunkApi) => {    
    var token = localStorage.getItem('token');    
    const response = await axios.post(`${getGeneratedEmailText}`, emailTextGenReqModel, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },      
        validateStatus: function (status) {
          return status < 500;
        },
      });
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }          
      return response.data;    
  }
);
export const getEmailGeneratedTextSlice = createSlice({
  name: "emailgeneratedtext",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailGeneratedText.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchEmailGeneratedText.fulfilled, (state, action) => {    
      state.emailTextGenResponseModel = action.payload;
      state.loading = false;
    }) 
    .addCase(fetchEmailGeneratedText.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});
export default getEmailGeneratedTextSlice.reducer;
