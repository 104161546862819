import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { deleteRole } from "../../networking/apiEndpoints";
import { IResponseModelState, ResponseModelState } from "../../types/components/responseModel";

const initialState: IResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    loading: false,
    error:"",
    showAlert:false,
    responseStatus: 200
};
export const deleteRoleApi = createAsyncThunk<
AxiosResponse,string,
{
rejectValue:AxiosResponse
}
>(
    "deleteRole/delete",
    async (roleId, thunkApi) => {
        var token = localStorage.getItem('token');     
            const response = await axios.delete(deleteRole + "/" + roleId,{
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;    
    }
);

export const deleteRoleSlice = createSlice({
    name: "deleteRole",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(deleteRoleApi.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(deleteRoleApi.fulfilled, (state, action) => {
            state.responseModel = action.payload.data; 
            state.loading = false;                       
        }) 
        .addCase(deleteRoleApi.rejected, (state, action) => { 
            state.loading = false;           
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });       
    },
});

export default deleteRoleSlice.reducer;