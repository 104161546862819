import React from "react";
import { FormHelperTextProps, TextField as MuiTextField} from "@mui/material";
import {TextFieldPropsSizeOverrides} from "@mui/material";
import { OverridableStringUnion } from '@mui/types';

interface TextFieldProps {
    id?: string;
    ariaLabel?: string;
    value?:string;
    maxLength?: number;
    minLength?: number;
    height?: number;
    width?: number;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean | false;
    helperText?: string;
    variant?: any;
    className?: string;
    hiddenLabel?: string;
    autoFocus?: boolean;
    readonly?: boolean;
    onKeyDown?:(event: React.KeyboardEventHandler<HTMLDivElement>)=> void;
    onKeyPress?:(event: React.KeyboardEventHandler<HTMLDivElement>)=> void;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    style?: React.CSSProperties;
    rows?: number | 0;
    textAlign?: AlignSetting | "left";
    multiline?: boolean | false;
    fullWidth?: boolean | false;
    autoComplete?: string;
    name: string;
    disableUnderline?: boolean | true;
    label?: string; 
    onChange?: (event: React.ChangeEvent<HTMLInputElement>)=> void;
    required?: boolean | false;
    type?: string;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
    formHelpTextProps? : Partial<FormHelperTextProps> | undefined;    
}

const TextBox = (props: TextFieldProps) => {
    return (
        <>
        <MuiTextField
        id={props.id}
        name={props.name ? props.name : ""}
        className= {props.className}
        disabled={props.disabled}  
        label={props.label ? props.label : ""}
        type={props.type}
        size={props.size ? props.size: "medium"}
        multiline = {props.multiline}
        required = {props.required}
        rows={props.rows}
        variant= {props.variant ? props.variant : "standard"}
        value={props.value}
        autoFocus={props.autoFocus} 
        inputProps={{
            maxLength: props.maxLength ? props.maxLength : "",
            minLength: props.minLength ? props.minLength : ""
        }}
        onChange={props.onChange}
        helperText={props.helperText}
        error={props.error}        
        fullWidth = {props.fullWidth?? true}   
        FormHelperTextProps={props.formHelpTextProps}     
        />
        </>
    );
};

export default TextBox;