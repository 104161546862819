import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {ContractTermListResponseState} from "../../types/components/contractDetailsTypes";
import axios, { AxiosResponse } from "axios";
import { getAttachedContractTerms } from "../../networking/apiEndpoints";
const initialState: ContractTermListResponseState = {
  data: [],
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchAttachContractTerms = createAsyncThunk<
  AxiosResponse,
  string,
  {
    rejectValue: AxiosResponse;
  }
>(
  "attachContractTerm/fetch",
  async (id, thunkApi) => {
    var token = localStorage.getItem('token');
    var tenantId = localStorage.getItem('tenantId');        
    const response = await axios.get(getAttachedContractTerms +"/"+ id +"/"+tenantId, {
        headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
    });
    if(response.status === 401)
        {
          return thunkApi.rejectWithValue(response);
        } 
    return response;   
  }
);



export const attachContractTermSlice = createSlice({
  name: "contractlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAttachContractTerms.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchAttachContractTerms.fulfilled, (state, action) => {    
      state.data = action.payload.data;
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
      state.loading = false;
    }) 
    .addCase(fetchAttachContractTerms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default attachContractTermSlice.reducer;
