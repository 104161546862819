import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {getSummaryTemplateList} from "../../../networking/apiEndpoints";
import { SummaryTemplateResponseState } from "../../../types/components/rass/summaryTemplateModel";
import { SummaryFilterModel } from "../../../types/components/rass/summaryFilterType";

const initialState: SummaryTemplateResponseState = {
  summaryTemplateResponseModel:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchSummartTemplateList = createAsyncThunk<
  any,
  SummaryFilterModel,
  {
    rejectValue: AxiosResponse;
  }
>(
  "summarytemplatelist/fetch", 
  async ( _SummaryFilterModelData:SummaryFilterModel, thunkApi) => {
    var token = localStorage.getItem('token');    
    const response = await axios.post(`${getSummaryTemplateList}`, _SummaryFilterModelData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },      
        validateStatus: function (status) {
          return status < 500;
        },
      });
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }      
      const transformedData = response.data.result.map((data)=>({
        id: data.id,
        name: data.name,
        type: data?.type === "S" ? "Standard" : (data?.type === "P" ? "Custom" : "Unknown"),
        text: data.text,
        templateName: data.emailTemplates? data.emailTemplates.map(template => template.name).join(', ') : '',
        updatedBy: data.updatedBy,
        updatedOn: data.updatedOn,

      })) ;
      return transformedData;    
  }
);
export const getSummaryTemplateSlice = createSlice({
  name: "summarytemplatelist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSummartTemplateList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchSummartTemplateList.fulfilled, (state, action) => {    
      state.summaryTemplateResponseModel = action.payload;
      state.loading = false;
    }) 
    .addCase(fetchSummartTemplateList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default getSummaryTemplateSlice.reducer;
