import { AlertProps } from "@mui/material/Alert/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IflashMessage {
    open : boolean,
    message : string,
    severity : AlertProps['severity'],
    duration? : number,
}

const initialState : IflashMessage = {
    open : false,
    message : "",
    severity : undefined,
    duration : 0,
}

const flashMessageSlice = createSlice({
    name : "flashMessage",
    initialState,
    reducers : {
        displayMessage : (state : IflashMessage, action : PayloadAction<IflashMessage>) => {
            state.open = action.payload.open;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
            state.duration = action.payload.duration;
        }
    }
})

export const {displayMessage} = flashMessageSlice.actions;
export default flashMessageSlice.reducer