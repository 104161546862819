import React, { useEffect } from 'react';
import { activityEvents } from '../utilities/Constants';

const InactivityHandler: React.FC = () => {

  useEffect(() => {
    let inactivityTimeout: NodeJS.Timeout;
    let sessionTimeout: NodeJS.Timeout;
    let sessionExpiryTime: number; 

    const clearAllTimeouts = () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      if (sessionTimeout) clearTimeout(sessionTimeout);
    };

    const logoutUser = () => {
      try {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      } catch (error) {
        console.error('Error during auto-logout:', error);
      }
    };

    const resetInactivityTimeout = () => {
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }

      const currentTime = Date.now();
      const remainingSessionTime = sessionExpiryTime - currentTime;

      if (remainingSessionTime <= 0) {
        logoutUser();
        return;
      }

      const inactivityDuration = Math.min(15 * 60 * 1000, remainingSessionTime);

      inactivityTimeout = setTimeout(() => {
        logoutUser();
      }, inactivityDuration);
    };

    const initializeTimeouts = () => {
      clearAllTimeouts();

      const loginTimeString = localStorage.getItem('loginTime');
      if (loginTimeString) {
        const loginTime = parseInt(loginTimeString, 10);
        const sessionDuration = 60 * 60 * 1000;
        sessionExpiryTime = loginTime + sessionDuration;

        const currentTime = Date.now();
        const remainingSessionTime = sessionExpiryTime - currentTime;

        if (remainingSessionTime <= 0) {
          logoutUser();
        } else {
          sessionTimeout = setTimeout(() => {
            logoutUser();
          }, remainingSessionTime);

          resetInactivityTimeout();
        }
      } else {
        logoutUser();
      }
    };

    activityEvents.forEach((eventName) => {
      window.addEventListener(eventName, resetInactivityTimeout);
    });

    initializeTimeouts();

    return () => {
      activityEvents.forEach((eventName) => {
        window.removeEventListener(eventName, resetInactivityTimeout);
      });
      clearAllTimeouts();
    };
  }, []);
  return null;
};

export default InactivityHandler;