import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ContractListByTypeIdResponseState,
  ContractListByTypeIdResponse,
} from "../../types/components/contractDetailsTypes";
import axios from "axios";
import {  
    getContractListByTypeId
} from "../../networking/apiEndpoints";

const initialState: ContractListByTypeIdResponseState = {
  contractListByTypeIdResponse: [],
  
};
export const fetchContractListByContractTypeId = createAsyncThunk(
  "contractListByTypeId/fetch",
  async (id:string) => {
    try {
      var token = localStorage.getItem('token');
      var tenantId = localStorage.getItem('tenantId');
      const response = await axios.get(getContractListByTypeId + '/' + id + '/' + tenantId,{
        headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const contractListByTypeIdSlice = createSlice({
  name: "contractlistByTypeId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {    
    builder.addCase(fetchContractListByContractTypeId.fulfilled, (state:ContractListByTypeIdResponseState, action: PayloadAction<ContractListByTypeIdResponse[]>) => {
      state.contractListByTypeIdResponse = action.payload;
    }); 
  },
});

export default contractListByTypeIdSlice.reducer;
