import React, {memo, ReactNode} from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  palette: {
    primary: {
      main:'#2b388f',
      light: '#757ce8',
      contrastText: '#fff',
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Roboto',
    body1: {
      fontWeight: 400,
      fontSize: 14,
    },
    button: {
      textTransform: 'none',
      fontSize:'15px',
      fontWeight: 500,

    }
  }
});

interface ThemeProviderProps{
    children: ReactNode;
}

const ThemeProviderWrapper = memo(({ children }: ThemeProviderProps)=>{
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
});

export default ThemeProviderWrapper