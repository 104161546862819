import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TextBox from "../components/cst/TextField";
import Button from "../components/cst/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";
import {  passwordNotMatch, resetPasswordExpired } from "../utilities/Constants";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  passwordResetAsyn,
  previousPasswordValidation,
} from "../redux/features/passwordReset";
import { PasswordResetModel } from "../types/components/passwordResetModel";
import CustomSnackbar from "../components/cst/CustomSnackbar";

const PasswordReset: React.FC<{}> = ({}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showPasswordFocus, setNewPasswordFocus] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [error, setError] = useState("");
  const [pwdResetValidation, setPwdResetValidation] = useState<{
    validation1?: Boolean;
    validation2?: Boolean;
    validation3?: Boolean;
    validation4?: Boolean;
    validation5?: Boolean;
  }>({});
  const [pwdFails, setpwdFails] = useState<boolean>(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [queryStringData] = useSearchParams();
  const loading = useAppSelector((state) => state.resetPasswordstore.loading);
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const handlePasswordSet = () => {
    setShowResult(true);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    const result = validatePassword(event.target.value);
    if (result) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
    setNewPasswordFocus(true);
    setConfirmPasswordFocus(false);
  };
  const handleConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
    setNewPasswordFocus(false);
    setConfirmPasswordFocus(true);
    setError("");
    setPwdResetValidation((prevState) => ({
      ...prevState,
      ["validation5"]: true,
    }));
  };
  const validatePassword = (password: string) => {
    const validations = {
      validation1: password.length >= 8,
      validation2: /[A-Z]/.test(password),
      validation3: /\d/.test(password),
      validation4: /[@$!%*?&]/.test(password),
    };
    setPwdResetValidation((prevState) => ({ ...prevState, ...validations }));
    // Check if all validations passed
    return Object.values(validations).every(Boolean);
  };
  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError(passwordNotMatch);
      return;
    }
    const forgotPasswordInstance: PasswordResetModel = {
      email: queryStringData.get("email") ?? "",
      newPassword: newPassword,
      token: queryStringData.get("token") ?? "",
    };

    dispatch(previousPasswordValidation(forgotPasswordInstance)).then(
      (response) => {
        if (response.payload) {
          var result = response.payload;
          if (!result.status) {
            setPwdResetValidation((prevState) => ({
              ...prevState,
              ["validation5"]: false,
            }));
          } else {
            dispatch(passwordResetAsyn(forgotPasswordInstance)).then(
              (response) => {
                if (response.payload) {
                  var result = response.payload;
                  if (result.status) {
                    handlePasswordSet();
                  } else if (result.message === "match") {
                    pwdResetValidation.validation5 = false;
                  } else {
                    setpwdFails(true);
                  }
                }
              }
            );
          }
        }
      }
    );
  };
  const navigate = useNavigate();

  const EnterPasswordPage = () => {
    if (!showResult) {
      return (
        <>
          <div className="password-reset-container">
            {loading && (
              <Box className="loadering-img">
                <CircularProgress />
              </Box>
            )}
            <div className="password-reset">
              <h1>Enter a New Password</h1>
              <div className="password-reset-form">
                <div className="password-icon-comb">
                  <TextBox
                    className="loginpassword"
                    name=""
                    id="password"
                    label="Enter a New Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    size="small"
                    required={true}
                    value={newPassword}
                    onChange={handleNewPassword}
                    autoFocus={showPasswordFocus}
                  />

                  <IconButton
                    className="password-icon"
                    id="password"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>

                <ul>
                  <li>
                    <strong>New password must contain</strong>
                  </li>
                  <li
                    className={
                      pwdResetValidation.validation1 === undefined
                        ? "password-rest-instruction"
                        : pwdResetValidation.validation1 === false
                        ? "password-error"
                        : "password-success"
                    }
                  >
                    At least 8 characters
                  </li>
                  <li
                    className={
                      pwdResetValidation.validation2 === undefined
                        ? "password-rest-instruction"
                        : pwdResetValidation.validation2 === false
                        ? "password-error"
                        : "password-success"
                    }
                  >
                    A uppercase letter
                  </li>
                  <li
                    className={
                      pwdResetValidation.validation3 === undefined
                        ? "password-rest-instruction"
                        : pwdResetValidation.validation3 === false
                        ? "password-error"
                        : "password-success"
                    }
                  >
                    A number
                  </li>
                  <li
                    className={
                      pwdResetValidation.validation4 === undefined
                        ? "password-rest-instruction"
                        : pwdResetValidation.validation4 === false
                        ? "password-error"
                        : "password-success"
                    }
                  >
                    A special character
                  </li>
                  <li
                    className={
                      pwdResetValidation.validation5 === undefined
                        ? "password-rest-instruction"
                        : pwdResetValidation.validation5 === false
                        ? "password-error"
                        : "password-success"
                    }
                  >
                    Don't use previously used password
                  </li>
                </ul>
                <div className="password-icon-comb">
                  <TextBox
                    name="confirmPassword"
                    id="confirmPassword"
                    label="Confirm New Password"
                    type={showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    size="small"
                    required={true}
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    autoFocus={confirmPasswordFocus}
                    error={Boolean(error)}
                    helperText={error}
                    className={
                      Boolean(error)
                        ? "errorField loginpassword"
                        : "loginpassword"
                    }
                  />

                  <IconButton
                    className="password-icon"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>

                <Button
                  className="saveButton"
                  type="contained"
                  text="SUBMIT"
                  disabled={!isButtonEnabled || loading}
                  onClick={handleSubmit}
                />
              </div>
            </div>

            {pwdFails && (
              <div>
                <CustomSnackbar message={resetPasswordExpired} severity="error" />
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="password-reset-container">
            <div className="password-reset">
              <h2>Password Successfully Changed!</h2>
              <div className="password-continue-to-login">
                <Button
                  className="saveButton"
                  type="contained"
                  text="Continue to Login"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="password-reset-waper">
      <div className="login-logo">
        <img
          src={`${process.env.PUBLIC_URL}/riverside-company-logo-login.svg`}
          alt="Logo"
        />
      </div>
      {showResult ? <EnterPasswordPage /> : <EnterPasswordPage />}
    </div>
  );
};
export default PasswordReset;
