import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getContractReport } from "../../networking/apiEndpoints";
import { ContractReportState } from "../../types/components/contractReport";

export const fetchContractReportList = createAsyncThunk(
    "contractReportModel/fetch",
    async (contractTypeId: string, thunkApi) => {         
            var token = localStorage.getItem('token');
            var tenantId = localStorage.getItem('tenantId');            
            const response = await axios.get(getContractReport + "/" + contractTypeId + "/" + tenantId ,{
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
              });
              if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }   
            return response.data;
    }
);
const initialState: ContractReportState = {
    data: [],
    loading: false,
    error: null,
    responseStatus:200
};

export const contractReportListSlice = createSlice({
    name: "contractReportlist",
    initialState,
    reducers: {
        clearContractReportData(state) {
            state.data = [];
            state.loading = false;
            state.error = null;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContractReportList.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchContractReportList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(fetchContractReportList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if(action.payload !== undefined)
                    {
                      state.responseStatus = 401;
                    }
            });
    },
});
export const {clearContractReportData} = contractReportListSlice.actions;
export default contractReportListSlice.reducer;