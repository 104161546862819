import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { EmailCreatorResponseState } from "../../../types/components/rass/emailCreatorModel";
import { getEmailCreatorVersionByEmailCreatorId } from "../../../networking/apiEndpoints";

const initialState: EmailCreatorResponseState = {
  emailCreatorListResponse: {
    status: false,
    message: "",
    result: null,
  },
  loading: false,
  error: null,
  responseStatus: 200,
};
export const fetchEmailCreatorVersionByEmailCreatorId = createAsyncThunk<
  AxiosResponse | any,
  any,
  {
    rejectValue: AxiosResponse;
  }
>("getEmailCreatorVersionByEmail/fetch", async (id, thunkApi) => {
  var token = localStorage.getItem("token");
  const response = await axios.get(
    getEmailCreatorVersionByEmailCreatorId + id,
    {
      headers: {
        "Content-Type": "*/*",
        Authorization: `Bearer ${token}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  if (response.status === 401) {
    return thunkApi.rejectWithValue(response);
  }
  const transformedDataEmailVersion = (response?.data?.result || []).map(
    (data: any) => ({
      ...data,
      emailVersionData: `Email Version ${data?.version}`,
    })
  );
  return transformedDataEmailVersion;
});

export const getEmailCreatorVersionByEmail = createSlice({
  name: "getEmailCreatorVersionByEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailCreatorVersionByEmailCreatorId.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(
        fetchEmailCreatorVersionByEmailCreatorId.fulfilled,
        (state, action) => {
          state.emailCreatorListResponse = action.payload?.data;
          state.loading = false;
        }
      )
      .addCase(
        fetchEmailCreatorVersionByEmailCreatorId.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message ?? "";
          if (action.payload !== undefined) {
            state.responseStatus = action.payload.status;
          }
        }
      );
  },
});

export default getEmailCreatorVersionByEmail.reducer;
