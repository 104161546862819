import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getContractDetails } from '../../networking/apiEndpoints';

// Define the initial state
interface ContractIdState {
  contractId: string | null;
}

const initialState: ContractIdState = {
    contractId: null,
};
export const fetchContractDetailsById = createAsyncThunk(
    "contractDetails/fetch",
    async (contractid : string) => {
        try{                
          var token = localStorage.getItem('token');
            const response = await axios.get(getContractDetails + "/" + contractid,{
              headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
            });                                   ;
            return response.data;
        } catch(err){
            console.log(err);
        }
    }
);
// Create a slice
const contractIdSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setContractId: (state, action: PayloadAction<string>) => {
      state.contractId = action.payload;
    },
  },
});

// Export the actions
export const { setContractId } = contractIdSlice.actions;

// Export the reducer
export default contractIdSlice.reducer;
