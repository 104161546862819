import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button";
import { DialogActions } from "@mui/material";

interface DialogProps {
  buttonOneText?: string;
  buttonTwoText?: string;
  buttonOneType?: string;
  buttonTwoType?: string;
  dialogOpen: boolean;
  dialogClose: () => void;
  dialogSetOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  yesBtnClick?: () => void;
  noBtnClick?: () => void;
  noBtnClass?: string;
  className?: string;
  classNameForDialogContent?: string;
  isCloseShow? : boolean;
}

const DialogWithTwoButton = (props: DialogProps) => {
  return (
    <Dialog
      open={props.dialogOpen}
      keepMounted
      aria-labelledby="alert-dialog-slide-description"
      className="dialog-container"
    >
    {(props.isCloseShow ?? true) ? (<IconButton
      aria-label="close"
      onClick={props.dialogClose}
      sx={{
        position: "absolute",
        right: 5,
        top: 0,
        color: "#333",
      }}
    >
      <CloseIcon />
    </IconButton>) : <div/>} 
      <DialogContent className="dialogTitle"></DialogContent>

      <div className={`dialog-container dialogActions justify-content-center align-items-center  ${props.className}`}>
        <p
          className="dialogTitle"
          id="alert-dialog-slide-desription"
          tabIndex={0}
        >
          <strong
            className="message-textpara"
            dangerouslySetInnerHTML={{ __html: props.message }}
          ></strong>
        </p>
        <div className="dialog-button d-flex justify-content-center align-items-center">
          <DialogActions>
            {props.yesBtnClick ? <Button
              onClick={props.yesBtnClick}
              className={`initial-setup-wrapper button-color commoncancelbutton`}
              type={props.buttonOneType ? props.buttonOneType : "outlined"}
              text={props.buttonOneText ? props.buttonOneText : "Yes"}
              intent="primary"
            /> : <div/>}
            {props.noBtnClick ? <Button
              onClick={props.noBtnClick}
              className="ml"
              type={props.buttonTwoType ? props.buttonTwoType : "contained"}
              text={props.buttonTwoText ? props.buttonTwoText : "No"}
              intent="primary"
            /> : <div/>}
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogWithTwoButton;
