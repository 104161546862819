import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DefaultActiveMenu } from "../../utilities/Constants";
interface MenuState {
    activeMenu : string | null;
}
const initialState: MenuState = {
    activeMenu: DefaultActiveMenu
};
const getMenuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setActiveMenuItem(state,action : PayloadAction<string>){            
            state.activeMenu =  action.payload;
        }
    },    
});
export const {setActiveMenuItem} = getMenuSlice.actions;
export default getMenuSlice.reducer;
