import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getContractFileStream } from "../../networking/apiEndpoints";
import { FileResponse } from "../../types/components/getContractFile";

const initialState: FileResponse = {
    FileResponse: null,
    IsLoading : false,
    responseStatus: 200
};

export const fetchContractFileByStream = createAsyncThunk<
AxiosResponse,string,
{
rejectValue:AxiosResponse
}
>(
    "getcontractfilestream/fetch",
    async (fileName, thunkApi) => {
            const response = await axios.post(getContractFileStream, fileName, {
                responseType: 'blob',headers:{"Content-Type": "application/json", Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            }); 
            if(response.status === 401)
                {
                    return thunkApi.rejectWithValue(response);
                }    
            return response;
    }
);
export const getContractFileSlice = createSlice({
    name: "getcontractfilebystream",
    initialState,
    reducers: {
        setPDFStream(state, action) {
            state.FileResponse = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContractFileByStream.fulfilled, (state, action) => {
              if (action.payload)
                state.FileResponse = action.payload.data;
                state.IsLoading = false;
        })
        .addCase(fetchContractFileByStream.pending, (state, action) => {
            state.IsLoading = true;
                })
        .addCase(fetchContractFileByStream.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });
    },
});
 export const { setPDFStream } = getContractFileSlice.actions;
export default getContractFileSlice.reducer;
