import * as React from "react";
import * as signalR from "@microsoft/signalr";
import { receiveNotificationContractProcessed } from "../../../networking/apiEndpoints";
import { useEffect, useState } from "react";

interface Message {
  content: string;
  timestamp: Date;
}
export default function NotificationStatus() {
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const conn = new signalR.HubConnectionBuilder()
      .withUrl(receiveNotificationContractProcessed)
      .withAutomaticReconnect()
      .withKeepAliveInterval(180000)
      .withServerTimeout(180000)
      .build();
    conn
      .start()
      .then(() => {
        console.log("SignalR Connected");
        console.log(conn.state);
        setConnection(conn);
      })
      .catch((error) => {
        console.log(conn.state);
      });
    conn.on("ReceiveNotification", (message) => {
      const newMessage: Message = {
        content: message,
        timestamp: new Date(),
      };
      if (message) {
        setMessages((prevMessage) => [...prevMessage, newMessage]);
        setNotifications(message);
      }
      console.log(message);
    });
    return () => {
      if (conn) {
        conn.stop();
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (connection) {
      const newIntervalId = setInterval(() => {
        connection
          .invoke("SendContractStatusNotification", "ping message")
          .catch((error) => {
            console.error("Error sending message", error);
          });
      }, 5000);
      setIntervalId(newIntervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [connection, messages]);
  return (
    <div>
      <p>Real Time Notification Testing</p>
      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{msg.content}</li>
        ))}
      </ul>
    </div>
  );
}
