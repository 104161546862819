import { useNavigate } from "react-router-dom";
import TextBox from "../components/cst/TextField";
import Button from "../components/cst/Button";

export default function ForgotPassword() {
  const navigate = useNavigate();
  return (
    <div className="verification-waper">
      <div className="login-logo">
        <img
          src={`${process.env.PUBLIC_URL}/riverside-company-logo-login.svg`}
          alt="Logo"
        />
      </div>
      <div className="verification-container">
        <p>
          Enter the verification code we just sent to your email address to
          proceed with the password rest process.
        </p>
        <div className="verification-form">
          <TextBox
            className="verificationcode"
            id="verification-code"
            name=""
            label="Enter Verification Code"
            variant="outlined"
            size="small"
            required={true}
          />

          <Button
            className="saveButton"
            type="contained"
            text="Verify"
            onClick={() => navigate("/password-reset")}
          />
        </div>
      </div>
    </div>
  );
}
