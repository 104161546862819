import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/cst/Button";
import SelectBox from "../../../components/cst/SelectBox";
import { FormControl, InputLabel } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";

import {
  getAllTenantApi,
  ResponseWithSelectedModelState,
  selectedTenant,
} from "../../../redux/features/getAllTenantSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { userInfoInterface } from "../../../types/pages/userInfoType";
import Handle401Status from "../../../types/components/handleApiReasponseStatus";
import {
  adminRoleName,
  userRoleName,
  FollowUpEmailCreator,
  Contracts,
} from "../../../utilities/Constants";
import { setActiveMenuItem } from "../../../redux/features/getMenuSlice";
import { useModuleSwitcher } from "../../../redux/features/moduleSwitcher/moduleSwicherHook";
import { usePermissionNavList } from "../../../layout/LeftNav/permissionNavHook";
import { usePortcoSetting } from "../../../redux/UserSlice/setPortcoHook";

interface LoginApiResponseResult {
  accessToken?: string;
  expiredIn?: string;
  userName?: string;
}
interface SinglePortco {
  id: string;
  name: string;
}
interface allTenantDataResultInterface {
  id: string;
  name: string;
  isCST?: boolean;
  isRRAS?: boolean;
}
interface allTenantDataInterface {
  status?: boolean;
  result: Array<allTenantDataResultInterface>;
  message?: string;
}
const PortcoSetting = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {setPortco} =  usePortcoSetting();
  const { LeftNavListREG, LeftNavListCST, navigateToVisibleItem } = usePermissionNavList();
  const loginDetail = useAppSelector((state) => {
    return state.login;
  });
  const {setActiveTab} = useModuleSwitcher();
  const [roles, setRoles] = useState([]);
  const [portcoName, setPortcoName] = useState<string>("");
  const [selectedPortco, setSelectedPortco] = useState("");
  const routePortcoInfo = useLocation();
  const allTenant: ResponseWithSelectedModelState = useAppSelector((state) => {
    return state.getAllTenant;
  });
  const allTenantData = allTenant?.responseModel as
    | allTenantDataInterface
    | undefined;

  const redirectToOther = useCallback(
    (locPortco: string, allTenants: allTenantDataResultInterface[]) => {
      setPortco(locPortco);
      if (allTenants && allTenants !== null) {
        const getNewSelection = allTenants.find(
          (el) => el.id === localStorage.getItem("tenantId")
        );
        if (getNewSelection?.isRRAS && !getNewSelection?.isCST) {
          navigateToVisibleItem(LeftNavListREG);
          setActiveTab(1);
        } else {
          navigateToVisibleItem(LeftNavListCST);
          setActiveTab(0);
        }
      }
    },
    [allTenantData]
  );

  const getAllTenant = async () => {
    let allTenants = [];
    await dispatch(getAllTenantApi()).then((getAllTenantApiResponse) => {
      setLoading(false);
      if (getAllTenantApiResponse.payload?.status === 401) {
        return Handle401Status(navigate, window);
      }
      if (getAllTenantApiResponse.payload?.data.status) {
        let tempData = getAllTenantApiResponse.payload.data.result.filter(
          function (val: SinglePortco) {
            return val.name.trim() !== "";
          }
        );
        tempData.sort((a: SinglePortco, b: SinglePortco) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setRoles(tempData);
        allTenants = getAllTenantApiResponse.payload.data.result;
      }
    });
    return allTenants;
  };
  useEffect(() => {
    (async function () {
      const isAuthenticated = sessionStorage.getItem("isAuthenticated");
      const allTenants: allTenantDataResultInterface[] = await getAllTenant();
      if (localStorage.getItem("userInfo") != undefined) {
      const getRoles = JSON.parse(
        localStorage.getItem("userInfo") ?? ""
      ) as userInfoInterface;
      if (
        isAuthenticated === "true" &&
        getRoles.Role !== adminRoleName &&
        getRoles.Role !== userRoleName
      ) {
      } else {
        setSelectedPortco(getRoles.TenantId);
        dispatch(selectedTenant(getRoles.TenantId));
        redirectToOther("notAllowed", allTenants);
      }
    }})();
  }, []);

  const handleChange = (tenantId: string) => {
    setSelectedPortco(tenantId);
    dispatch(selectedTenant(tenantId));
    localStorage.setItem("tenantId", tenantId);
  };

  useEffect(() => {
    if (routePortcoInfo.pathname === "/rras-follow-up-email-creator") {
      dispatch(setActiveMenuItem(FollowUpEmailCreator));
    } else if (routePortcoInfo.pathname === "/") {
      dispatch(setActiveMenuItem(Contracts));
    }
  }, [portcoName]);

  return (
    <>
      <div className="login-waper portco-setting">
        <div className="middle-ofwindow">
          <div className="login-logo">
            <img
              src={`${process.env.PUBLIC_URL}/riverside-company-logo-login.svg`}
              alt="Logo"
            />
          </div>
          <div className="login-container">
            {loading && (
              <div className="protco-loader">
                <CircularProgress />
              </div>
            )}
            <div className="middle">
              <h2 className="mb-06">Portco Selection</h2>
              <div className="login-form">
                <FormControl
                  size="small"
                  sx={{ minWidth: "100%" }}
                  className="portco-drodown"
                >
                  <InputLabel>Select Portco</InputLabel>
                  <SelectBox
                    label="Select Portco *"
                    value={selectedPortco}
                    ListData={roles}
                    onChange={(e) => {
                      handleChange(e.target.value);
                      setPortcoName(e.target.value);
                    }}
                    error={false}
                    errorText="test"
                    disabled={false}
                    displayEmpty={false}
                    disableUnderLine={true}
                    variant={true}
                    selectBydefaultChoosen={false}
                  />
                </FormControl>

                <Button
                  className="saveButton mt-03"
                  type="contained"
                  text="Enter"
                  onClick={() =>
                    redirectToOther("allowed", allTenantData?.result || [])
                  }
                  disabled={["0", ""].indexOf(selectedPortco) !== -1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PortcoSetting;
