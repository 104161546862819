import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { displayMessage } from "./snackFlashMessageSlice";
import { AlertProps } from "@mui/material";

export const useFlashMessage = () => {
    const {open,message,severity, duration} = useSelector((state : RootState) => state.flashMessage);
    const dispatch = useAppDispatch();
    const showFlashMessage = (message: string, severity: AlertProps['severity'], duration?: number) => {
        dispatch(displayMessage({message,severity,open:true, duration}))
    }
    const clearFlashMessage = () => {
        dispatch(displayMessage({message:"", severity:undefined, open:false, duration: 0}))
    }
    return {showFlashMessage, clearFlashMessage, open, message, severity, duration}
} 