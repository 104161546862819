import React,{useEffect} from "react";
import {Select, MenuItem, SelectChangeEvent, FormHelperText, Checkbox, ListItemText} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


interface SelectBoxProps {
    id?: string;
    displayEmpty: boolean;
    disabled: boolean;
    value: string;
    onChange: (e: SelectChangeEvent) => void;
    className?: string;
    placeHolder?: string;
    ListData:any[] | undefined;
    itemName?: string;
    error: boolean;
    errorText: string;
    height?: string;
    disableUnderLine?: boolean;
    placeHolderColor?: string;
    ariaLabelTest?: string;
    labelId?: string;
    formId?: string;
    sampleData?: string[];
    MenuProps?: any;
    label?: string;
    variant?: boolean | false;
    name?:string;
    multipleSelect?: boolean | false;
    selectedTenents?: string[];
    selectBydefaultChoosen?: boolean
}

const SelectBox: React.FC<SelectBoxProps> = (props: SelectBoxProps) => {
    return (
        <>
            <Select
                name={props.name}
                id={props.id}
                label={props.label}
                displayEmpty={props.displayEmpty}
                disabled={props.disabled}
                disableUnderline={props.disableUnderLine}
                value={props.value}
                onChange={props.onChange}
                inputProps={{id: props.formId}}
                variant= {props.variant ? "outlined" : "filled"}
                style={{
                    fontSize:14,
                    backgroundColor:"#ffffff",
                    width:"100%",
                    color:"rgba(0, 0, 0, 0.38)",
                }}
                className={props.className}
                IconComponent={KeyboardArrowDownIcon}
                labelId={props.labelId}
                MenuProps={props.MenuProps}             
                >
                 {(typeof props?.selectBydefaultChoosen === "undefined" || (typeof props?.selectBydefaultChoosen !== "undefined" && props?.selectBydefaultChoosen)) && <MenuItem key={'0'} value={'0'}>Select</MenuItem>}
                 {props?.ListData && props?.ListData.map((data: any) => (
                                            <MenuItem key={data.name} value={data.id}>
                                            {data.name}
                                            </MenuItem>
                                        ))}                       
                            </Select> 
                            {props.error &&     
            <FormHelperText style={{ color: "#d32f2f" }}>{props.error ? props.errorText : ""}</FormHelperText>
                            }
        </>
    );
};

export default SelectBox;