export const DocumentNameErrorText = "Document Name is required.";
export const CancelDialogMessage =
  "Are you sure you do not want to upload the selected file(s)?";
export const UserAddCancelDialogMessage =
  "Are you sure you want to discard the changes without saving ?";
export const SummaryTemplateAddCancelDialogMessage =
  "Your changes will be lost. Are you sure you want to exit?";
export const MAX_FILE_SIZE = 25600;
export const FileUploadMsg = "File upload successfully.";
export const FileUploadErrorMsg = "File size limit exceeded.";
export const FileSizeText = "Only select PDF files";
export const DeleteContractDialogMessage =
  "Are you sure you want to delete this contract?";
export const DeleteMultipleContractDialogMessage =
  "Are you sure you want to delete the selected contract(s)?";
export const DeleteContractSuccessMessage = "Record deleted successfully.";
export const CancelDialogContractType =
  "Are you sure you want to discard the changes without saving?";
export const FailedDuplicateCheck = "Failed to check duplicate contract type";
export const ContractTypeExists = "Contract type already exists";
export const FailToSaveContractType = "Failed to save contract type";
export const SaveContractType = "Contract type saved successfully";
export const RequiredFields = "This field is required";
export const EditContractSavedMsg = "Contract saved successfully";
export const EditContractSavedFailedMsg = "Failed to save contract";
export const EditContractCreatedMsg = "Contract created successfully";
export const EditContractFailedMsg = "Failed to create contract";
export const AlphaNumericWithSpace = "Value must be alphanumeric and can include spaces";
//export const Method = [{ 'id':'EgnyteAPI' , 'name' :'EgnyteAPI' },{ 'id':'Claude' , 'name' :'AWS Claude' }]

export const Operator = [
  { id: "Contains", name: "Contains" },
  { id: "NotContains", name: "Does not Contain" },
  { id: "Equal", name: "= (equal to)" },
  { id: "NotEqual", name: "!= (not equal to)" },
  { id: "GreaterThan", name: "> (greater than)" },
  { id: "GreaterThanEqual", name: ">= (greater than equal to)" },
  { id: "LessThan", name: "< (less than)" },
  { id: "LessThanEqual", name: "<= (less than equal to)" },
];
export const DataType = [
  { id: "DateTime", name: "DateTime" },
  { id: "Boolean", name: "Boolean" },
  { id: "Integer", name: "Integer" },
  { id: "String", name: "String" },
];
export const YesNo = [
  { id: "true", name: "Yes" },
  { id: "false", name: "No" },
];
export const SummaryTemplate = [
  { id: "SummaryTemplate", name: "Summary Template 1" },
];
export const TemplateDepartment = [
  { id: "TemplateDepartment", name: "Template Department 1" },
];
export const FailToSaveContractTerm = "Failed to save contract term";
export const IsContractTermExists =
  "Contract term already exists with same term name & contract type.";
export const SaveContractTerm = "Contract term saved successfully";
export const DiscardDialogMessage =
  "Are you sure you want to discard the changes without saving?";
export const DeleteContractTypeSuccessMessage =
  "Contract Type has been deleted.";
export const DeleteContractTypeErrorMessage = "Failed to delete Contract Type.";
export const MaxFileSelectMsg =
  "Maximum of hundred[100] contracts can be selected.";
export const FileType = "application/pdf";
export const DeleteContractTermDialogMessage =
  "<p>Are you sure you want to delete the Contract Term <span>&quot;{contract_term_name}&quot;</span> ? Associated Contract Type <span>&quot;{contract_type_name}&quot;</span> will be impacted.</p>";
export const DeleteContractTermFailMessage = "Fail to deleted contract term.";
export const DefaultActiveMenu = "ContractManagement";
export const FileSizeError =
  "Request Files Too Large or Contract Name already exists";
export const feedbackAdd = "Feedback added successfully";
export const AddNewUserMsg = "New user added successfully";
export const FromDateFutureValidationMsg = "From Date cannot be a future date.";
export const ToDateFutureValidationMsg = "To Date cannot be a future date.";
export const FromDateGreaterThen =
  "From Date should always be less than or equal to To Date.";
export const ToDateGreaterThen =
  "To Date should always be greater than or equal to From Date.";
export const regExEmail =
  /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/;
export const forgotPasswordSucess = "Password reset email sent successfully.";
export const forgotPasswordFail = "Password reset fail.";
export const passwordNotMatch = "Password confirmation does not match.";
export const resetPasswordFail = "Fail to reset the password.";
export const resetPasswordExpired = "Failed to reset password. Token expired.";
export const userProfileMenu = ["My Profile", "Change Password", "Logout"];
export const superUserRoleName = "Super User";
export const userRoleName = "User";
export const systemAdminRoleName = "System Administrator";
export const adminRoleName = "Administrator";
export const EditProfileCancelDialogMessage =
  "Are you sure you want to discard the changes without saving ?";
export const ReanalyzeError = "Error Processing Reanalyze.";
export const ContractStatus = [
  { id: "InProgress", name: "In Progress" },
  { id: "Completed", name: "Completed" },
  { id: "Reanalyze", name: "Reanalyze" },
  { id: "Failed", name: "Failed" },
];
export const AllowedStatusesForReanalyze = ["ReAnalyze", "Failed"];
export const OnlyNumericalsAllowed =
  "Only numerical characters (between 1 to 999) will be allowed.";
export const OnlyNumericalsWithTwoDecimals =
  "Enter only numerical characters and decimals up to two places (0.00 - 999999.99).";
export const DuplicateOrderNo =
  "Order # for the selected contract type already exists. Use a different order #";
export const AttachChildContract = "Contracts attached successfully.";
export const AddChildContractFail = "Fail to add the child contract.";
export const NoAttachments = "No Contract attachments found";
export const DeleteContractWithChildDialogMessage =
  "Deleting will disassociate all contracts linked to it. Are you sure you want to delete the contract?";
export const DeleteChildContractDialogMessage =
  "Removing will disassociate it from the main contract. Are you sure you want to remove the linked contract?";
export const RemovedContractSucessfully = "Contracts removed successfully";
export const FailToRemovedContract = "Fail to remove contract";
export const FailToSetPrecedence = "Fail to set precedence";
export const ChildContractPrecedenceSetSucessfully =
  "Precedence has been set successfully";
export const SourceTextToDisplayInContractTerms =
  'Also extract Source as "Source:" from where the entity is extracted with paragarah and page number.';
export const FailToSaveFollowUpEmail = "Failed to save follow-up email";
export const SaveFollowUpEmail = "Summary and email are saved successfully.";
export const CancelDialogFollowUpEmail =
  "Are you sure you want to discard the changes without saving?";
export const SourceTextToDisplayInContractTermsForGemini =
  'Also extract Source as "**Source:**" from where the entity is extracted with paragarah and page number where all source to display under one heading.';
export const NotSuccessfull = "Not Successfull, ";
export const DuplicateContractTerm = "already exists";
export const InvalidOrderNo = "Invalid Order #. Cannot execute the rule.";
export const ColumnNameType = "TYPE";
export const EmailConstants = {
  contentSummary: "Content Summary",
  generatedEmail: "Generated Email",
  createEmail: "Create Email & Summary",
  viewEmail: "View Email & Summary",
  emailVersion: "Email Version",
  previewTemplates: "Preview Templates",
  emailTemplateText: "Email Template Text",
  summaryTemplateText: "Summary Template Text",
};
export const CreateEmailConstants = {
  useSummaryInsteadTranscript: "Use Summary instead of Transcript",
  generateSummary: "Generate Summary only",
};
export const ValidateOrderNo =
  "Set Order # cannot be less than or equal to Execute and Else order numbers.";
export const RegenerateDialogMessage =
  "This will overwrite the current summary/email content. Would you like to continue?";
export const DisclaimerCSVReportMessage =
  "Disclaimer!  Do not rely on this report or any other output produced by this system as a substitute for legal advice. This report is for informational purposes only.";

export const VersionTypeAIGenerated = "AI generated";
export const VersionTypeManual = "Manual";
export const FollowUpEmailCreator = "Meeting Summary & Email";
export const Contracts = "Contracts";
export const Success = "success";
export const Error = "error";
export const SummaryTemplateClonedSuccessfully =
  "Summary Template Cloned successfully";
export const SummaryTemplateSavedSuccessfully =
  "Summary Template saved successfully";
export const defaultPageNumber = "25";
export const AddSummaryTemp = "Add Summary Template";
export const EditSummaryTemp = "Edit Summary Template";
export const ViewSummaryTemp = "View Summary Template";
export const StandardSummaryTemp = "Standard Summary Template";
export const MAX_NAME_LENGTH = 100;
export const MAX_TEXT_LENGTH = 5000;
export const DEFAULT_HIDEOUT_TIME = 6000;
export const NAME_FIELD_LABEL = "Template Name *";
export const TEXT_FIELD_LABEL = "Content *";
export const EDIT_EMAIL_SUMMARY = "Edit Email & Summary";
export const DEPARTMENT_NAME = "Department Name";
export const DeleteEmailFollowUpMessage ="Deleting this email will also delete previous email versions. Are you sure you want to delete this email?"
export const DeleteEmailFollowUpSuccessMessage =
  "FollowUp email has been deleted successfully.";
export const DeleteCustomEmailTemplateSuccessMessage =
  "Custom Email Template has been deleted successfully.";
export const DeleteEmailFollowUpErrorMessage = "Failed to delete FollowUp email.";
export const DeleteCustomEmailTemplateErrorMessage = "Failed to delete Custom email template.";
export const NotDeleteStandardTemplateMessage = "You cannot delete Standard Email Templates.";
export const AssociatedEmailTemplateMessage = "This email template is associated with follow-up email(s). Remove the association and then try to delete the email template.";
export const EmailTemplateClonedSuccessfully = "Email Template Cloned successfully";
export const EmailTemplatePopUpMessage = "Are you sure you want to delete this email template?";
export const CancelDialogEmailTemplate = "Your changes will be lost. Are you sure you want to exit?";
export const FailToSaveEmailTemplate = "Failed to save email template";
export const SaveEmailTemplate = "Email template saved successfully";
export const AddEmailTemplateHeading = "Add Email Template";
export const EditEmailTemplateHeading = "Edit Email Template";
export const ViewEmailTemplateHeading = "View Email Template";
export const StandardEmailTemp = "Standard Email Template";
export const CustomEmailTemplateType = "Custom";
export const RoleNameAlphaNumericWithSpace = "Role Name must be alphanumeric and can include spaces";
export const RoleNameErrorText = "Role Name is required.";
export const regExAlphanumeric =/^[a-zA-Z0-9\s]+$/;
export const InvalidRoleId = "Invalid RoleId!";
export const GeneratedEmail = "Would you also like to generate email?";
export const AiTextWarning = "This email version is AI generated. You must edit the generated email draft before sending.";
export const ContentCopied = "Content copied successfully"
export const RoleCancelDialogMessage = "Your changes will be lost. Are you sure you want to cancel?";
export const UserPermissionsCancelDialogMessage =
  "Your changes will be lost. Are you sure you want to cancel?";
export const AssignedUsersCancelDialogMessage =
  "Your changes will be lost. Are you sure you want to exit?";
export const generateEmailLoaderWithText = "All generated text must be edited before sending externally"
export const DeleteSummaryTemplateMessage ="Are you sure you want to delete the template?"
export const DeleteSummaryTemplateSuccessMessage =  "Summary Template deleted successfully.";
export const DeleteSummaryStandardTemplateMessage =  "You cannot delete Standard Summary Templates.";
export const DeleteSummaryTemplateErrorMessage =  "Error Deleting Summary Template.";
export const DeleteSummaryTemplateAssociatedWithEmailMessage =  "This summary template is associated with email template(s). Remove the association and then try to delete the summary template";
export const activityEvents: string[] = ['click','mousemove','mousedown','keydown','scroll','touchstart','touchmove','wheel','visibilitychange','focus','resize',];
export const RegModuleMessage = "When using the Riverside Engage Genius, you are required to edit all generated text before sending externally. Failure to comply may result in disciplinary action, up to and including termination of employment.";
export const DeleteRoleMessage = "Are you sure you want to delete this role <span>&quot;{role_name}&quot;</span> ?";
export const RoleUnassignedToUser = "This role is assigned to users. You must first unassign the user(s) to delete this Role.";