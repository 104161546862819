import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { displaySelectedTab } from "./moduleSwitcherTabSlice";

export const useModuleSwitcher = () => {
    const {activeTabIndex } = useSelector((state : RootState) => state.moduleSwitcher);
    const dispatch = useAppDispatch();
    const setActiveTab = (activeTabIndex: number) => {
        dispatch(displaySelectedTab({activeTabIndex}))
    }
    return {setActiveTab,activeTabIndex}
} 