import { RouteObject } from "react-router-dom";
import React, { lazy } from "react";

const Profile = lazy(() => import("../pages/cst/Profile"));
const EditProfile = lazy(() => import("../pages/cst/Profile/editprofile"));
const ChangePassword = lazy(() => import("../login/ChangePassword"));
const RolesAndPermissions = lazy(
  () => import("../components/SharedComponents/RolesAndPermissions")
);
const UpsertRoles = lazy(() =>
  import("../components/SharedComponents/RolesAndPermissions/UpsertRoles").then(
    (module) => ({ default: module.UpsertRoles })
  )
);
const AddUser = lazy(() =>
  import("../components/SharedComponents/UserList/AddUser").then((module) => ({
    default: module.AddUser,
  }))
);
const UserList = lazy(() => import("../components/SharedComponents/UserList"));
const EditUser = lazy(
  () => import("../components/SharedComponents/UserList/EditUser")
);
const AssignUsers = lazy(() => import("../components/SharedComponents/RolesAndPermissions/AssignUsers"));

const userRoutes: RouteObject[] = [
  { path: "/user-add", element: <AddUser /> },
  { path: "/user-list", element: <UserList /> },
  { path: "/user-edit/:id", Component: () => <EditUser /> },
  { path: "/roles-and-permissions", element: <RolesAndPermissions /> },
  { path: "/role-add", element: <UpsertRoles /> },
  { path: "/role-edit/:id", element: <UpsertRoles /> },
  { path: "/profile", element: <Profile /> },
  { path: "/edit-profile/:id", Component: () => <EditProfile /> },
  { path: "/change-password", element: <ChangePassword /> },
  { path: "/assign-users/:id", element: <AssignUsers /> },
];

export default userRoutes;
